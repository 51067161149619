import { type ComboBoxItem, type ComboBoxProps } from '@oms/frontend-foundation';
import {
  PROCESS_ID,
  type ActorSchema,
  type AnyRecord,
  type CommonWindowActorSchemaOptions,
  type WindowOperations
} from '@valstro/workspace';
import type { Observable } from 'rxjs';
import type { AppWindowContext } from '@app/app-config/workspace.config';

export const COMMON_COMMAND_PALETTE = {
  BASE_ID: 'cmdp-window',
  getId: (rootWindowId: string = PROCESS_ID.LEADER) => `${COMMON_COMMAND_PALETTE.BASE_ID}-${rootWindowId}`,
  TYPE: 'cmdp-window',
  HEIGHT: 400
} as const;

export type CommandPaletteOpenOptions = {
  items?: ComboBoxProps['items'];
  multiSelect?: boolean;
  selectedItems?: ComboBoxProps['selectedItems'];
  placeholder?: ComboBoxProps['placeholder'];
  removeOnBackspace?: ComboBoxProps['removeOnBackspace'];
  hiddenSelectedItems?: ComboBoxProps['hiddenSelectedItems'];
  submitReady?: ComboBoxProps['submitReady'];
  strategy?: ComboBoxProps['strategy'];
  isLoading?: ComboBoxProps['isLoading'];
  allowAnyValue?: ComboBoxProps['allowAnyValue'];
  isInvalidated?: ComboBoxProps['isInvalidated'];
  errorMessage?: ComboBoxProps['errorMessage'];
};

export type CommandPaletteContext = AppWindowContext & CommandPaletteOpenOptions;

export type CommandPaletteOperations<TMeta extends AnyRecord = AnyRecord> = WindowOperations<TMeta> & {
  openPalette: (options?: CommandPaletteOpenOptions) => Promise<void>;
  updatePalette: (options: CommandPaletteOpenOptions) => Promise<void>;
  closePalette: () => Promise<void>;
};

export const EMPTY_COMMAND_PALETTE_CONTEXT: Partial<CommandPaletteContext> = {
  selectedItems: [],
  placeholder: 'Start typing...',
  removeOnBackspace: true,
  hiddenSelectedItems: false,
  submitReady: false,
  strategy: 'default',
  isLoading: false,
  allowAnyValue: false,
  isInvalidated: false,
  errorMessage: '',
  multiSelect: false
};

export type CommonCommandPaletteActorSchemaOptions = CommonWindowActorSchemaOptions & {
  open$?: Observable<CommandPaletteOpenOptions>;
  update$?: Observable<CommandPaletteOpenOptions>;
  close$?: Observable<unknown>;
  selectedItemsChange$?: Observable<ComboBoxProps['items']>;
};

export type CommonCommandPaletteActorSchema = ActorSchema<
  CommandPaletteContext,
  CommandPaletteOperations<AnyRecord>,
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  React.FC<{}>,
  CommonCommandPaletteActorSchemaOptions
>;

/**
 * Contracts for the Command Palette Service
 */
export const COMMAND_PALETTE = {
  CATEGORY_CONTEXT: 'Contextual',
  CATEGORY_ALL: 'All',
  CATEGORY_UNKNOWN: 'Unknown',
  CATEGORY_GROUP_UNKNOWN: 'Unknown'
} as const;

export type CommandPaletteItem = {
  category?: string;
  isContextual?: boolean;
  group?: string;
  id: ComboBoxItem['id'];
  label: ComboBoxItem['label'];
  sublabel?: ComboBoxItem['sublabel'];
  searchValues?: ComboBoxItem['searchValues'];
  shortcut?: ComboBoxItem['shortcut'];
  isDisabled?: ComboBoxItem['isDisabled'];
  iconId?: ComboBoxItem['iconId'];
  value?: ComboBoxItem['value'];
  onSelect?: (item: ComboBoxItem, context: CommandPaletteSubmitContext) => void | Promise<void>;
};

export type CommandPaletteSubmitContext = {
  windowId: string;
};

export type CommandPaletteSubmitEvent = {
  items: ComboBoxItem[];
  context: CommandPaletteSubmitContext;
};

export type CommandPaletteCategory = {
  isContextual: boolean;
  label: string;
};

export type CommandPaletteCategoryGroup = {
  category: string;
  label: string;
};

export type CommandPaletteUnregisterContextItems = () => Promise<void>;

export type CommandPaletteMemoryState = {
  items: CommandPaletteItem[];
  categories: CommandPaletteCategory[];
  categoryGroups: CommandPaletteCategoryGroup[];
  categoriesOrder: string[];
};

export const initialCommandPaletteMemoryState: CommandPaletteMemoryState = {
  categories: [
    {
      isContextual: false,
      label: COMMAND_PALETTE.CATEGORY_ALL
    }
  ],
  categoryGroups: [],
  categoriesOrder: [],
  items: []
};
