import type { Trade, TradeRepairRequestFragment, Maybe } from '@oms/generated/frontend';
import type { RepairTradeEventPayload } from './trade-modify.form-common';

// Get parsed payload from sourceEventPayload.
// Recursively apply JSON.parse to sourceEventPayload until it is parsed and we get Object from string.
function getParsedPayload(sourceEventPayload: string | RepairTradeEventPayload): Maybe<RepairTradeEventPayload> {
  if (typeof sourceEventPayload === 'string') {
    try {
      return getParsedPayload(JSON.parse(sourceEventPayload) as string);
    } catch (error) {
      console.error('Error parsing sourceEventPayload', error);
      return null;
    }
  } else {
    return sourceEventPayload;
  }
}

export function getTradeFromRepairEvent(tradeRepair: TradeRepairRequestFragment): Trade {
  const { sourceEventPayload } = tradeRepair;
  const parsedPayload = getParsedPayload(sourceEventPayload);

  if (!parsedPayload) {
    throw new Error('Unable to parse sourceEventPayload');
  }

  if (!parsedPayload.originalCommand) {
    throw new Error('originalCommand is missing the original Trade command.');
  }

  if (!parsedPayload.originalCommand.trade) {
    throw new Error('The original Trade command is missing the Trade.');
  }

  return parsedPayload.originalCommand.trade;
}

export function getTradingNodeFromRepairEvent(tradeRepair: TradeRepairRequestFragment): Trade {
  const { sourceEventPayload } = tradeRepair;
  const parsedPayload = getParsedPayload(sourceEventPayload);

  if (!parsedPayload) {
    throw new Error('Unable to parse sourceEventPayload');
  }

  if (!parsedPayload.tradingOrder) {
    throw new Error('tradingOrder is missing the original Trade command.');
  }

  if (!parsedPayload.tradingOrder.trades) {
    throw new Error('The Trades in tradingOrder command is missing.');
  }

  const { trades: { nodes } }= parsedPayload.tradingOrder;
  if (!nodes || (!Array.isArray(nodes)) || nodes.length === 0 || !nodes[0]) {
    throw new Error('The Trades array in tradingOrder command is missing, empty or has null/undefined contents.');
  }
  return nodes[0];
}
