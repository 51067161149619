import {
  validatorTypes,
  type ActionMapper,
  type ComponentMapper,
  type Validator
} from '@data-driven-forms/react-form-renderer';
import type { ConditionMapper } from '@oms/frontend-foundation';
import { FORM_COMPONENT_TYPE, FORM_VALIDATOR_TYPE, FORM_ACTION_TYPE } from '../common/form.contracts';
import { ExampleField, type IExampleField } from '../field-components/example-field/example-field.component';
import { AllFields } from '../fields/additional-fields/all-fields.component';
import { EnrichedField } from '../fields/enriched-field/enriched-field.component';
import { AdditionalFields } from '../fields/additional-fields/additional-fields.component';
import { MarketDataField } from '../fields/market-data-field/market-data-field.component';
import { CoverageUserField } from '../fields/coverage-user-field/coverage-user-field.component';
import { validateInstrumentTypeahead } from '@app/forms/common/fields/symbol-field/symbol.field.validators';
import {
  type IPrincipalFillGrid,
  PrincipalFillGrid
} from '@app/widgets/trading/principal-fill/principal-fill-field/principal-fill-field.component';
import { afterDate } from '@app/forms/common/validators/after-date/after-date.validator';
import { futureGTD } from '@app/forms/common/validators/future-gtd/future-gtd.validator';
import { disableWhen } from '@app/forms/common/actions/disable-when/disable.when.action';
import { minNumberValue } from '@app/forms/common/validators/min-number-value/min-number-value.validator';
import {
  CrossPrincipalFillOrdersGrid,
  type ICrossPrincipalFillOrdersGrid
} from '@app/widgets/trading/cross-principal-fill/cross-principal-fill-orders-field/cross-principal-fill-orders-field.component';
import {
  MatchedInvestorOrdersField,
  type IMatchedInvestorOrdersField
} from '@app/widgets/trading/route-order/fields/matched-investor-orders/matched-investor-orders.component';
import {
  FIXatdlStrategyField,
  type IFixatdlStrategyField
} from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.component';
import { onlyIfRequired } from '@app/forms/common/validators/only-if-required/only-if.required.validator';
import { optionalRequired } from '@app/forms/common/validators/optional-required/optional.required.validator';
import {
  ActionButtonLayoutField,
  type IActionButtonLayoutField
} from '@app/actions/forms/action-button-layout/action-button-layout.field';
import {
  type ITradeThroughObligation,
  TradeThroughObligation
} from '@app/widgets/trading/internalization-common/trade-through-obligation-field.component';
import type { Field, Schema } from '@oms/frontend-foundation';
import { validateDurationInput } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import { validateActionButtonLayout } from '@app/actions/forms/action-button-layout/action-button-layout.field.validator';
import { validateTifInput } from '@app/forms/common/validators/tif-input/tif-input.validator';

// !IMPORTANT
// Note, only use this file for shared / global mappers for fields
// Otherwise, co-locate them with the form that uses them
// And import them into app.mapper.form.ts

/**
 * Component mapper for form fields.
 */
export const FORM_COMPONENT_MAPPER: ComponentMapper = {
  [FORM_COMPONENT_TYPE.EXAMPLE]: ExampleField,
  [FORM_COMPONENT_TYPE.PRINCIPAL_FILL]: PrincipalFillGrid,
  [FORM_COMPONENT_TYPE.CROSS_PRINCIPAL_FILL]: CrossPrincipalFillOrdersGrid,
  [FORM_COMPONENT_TYPE.ALL_FIELDS]: AllFields,
  [FORM_COMPONENT_TYPE.ADDITIONAL_FIELDS]: AdditionalFields,
  [FORM_COMPONENT_TYPE.ENRICHED_FIELD]: EnrichedField,
  [FORM_COMPONENT_TYPE.ACTION_BUTTON_LAYOUT]: ActionButtonLayoutField,
  [FORM_COMPONENT_TYPE.MARKET_DATA_FIELD]: MarketDataField,
  [FORM_COMPONENT_TYPE.FIXATDL_STRATEGY]: FIXatdlStrategyField,
  [FORM_COMPONENT_TYPE.MATCHED_INVESTOR_ORDERS_FIELD]: MatchedInvestorOrdersField,
  [FORM_COMPONENT_TYPE.TRADE_THROUGH_OBLIGATION]: TradeThroughObligation,
  [FORM_COMPONENT_TYPE.COVERAGE_USER_FIELD]: CoverageUserField
};

export type AdditionalFormFieldUnion =
  | IExampleField<Validator>
  | IPrincipalFillGrid<Validator>
  | IActionButtonLayoutField<Validator>
  | IFixatdlStrategyField<Validator>
  | ICrossPrincipalFillOrdersGrid<Validator>
  | ICrossPrincipalFillOrdersGrid<Validator>
  | IMatchedInvestorOrdersField<Validator>
  | ITradeThroughObligation<Validator>;

export type AppField = AdditionalFormFieldUnion | Field;

export type AppSchema = Schema<AppField>;

/**
 * Validator mapper for form fields.
 */
export const FORM_VALIDATOR_MAPPER = {
  [FORM_VALIDATOR_TYPE.VALIDATE_INSTRUMENT_TYPEAHEAD]: validateInstrumentTypeahead,
  [FORM_VALIDATOR_TYPE.AFTER_DATE]: afterDate,
  [FORM_VALIDATOR_TYPE.FUTURE_GTD]: futureGTD,
  [validatorTypes.MIN_NUMBER_VALUE]: minNumberValue,
  [FORM_VALIDATOR_TYPE.VALIDATE_DURATION_INPUT]: validateDurationInput,
  [FORM_VALIDATOR_TYPE.ONLY_IF_REQUIRED]: onlyIfRequired,
  [FORM_VALIDATOR_TYPE.OPTIONAL_REQUIRED]: optionalRequired,
  [FORM_VALIDATOR_TYPE.VALIDATE_ACTION_BUTTON_LAYOUT]: validateActionButtonLayout,
  [FORM_VALIDATOR_TYPE.VALIDATE_TIF_INPUT]: validateTifInput
};

/**
 * Action mapper for form fields.
 */
export const FORM_ACTION_MAPPER: ActionMapper = {
  [FORM_ACTION_TYPE.DISABLE_WHEN]: disableWhen
};

/**
 * Condition mapper for form fields.
 */
export const FORM_CONDITION_MAPPER: ConditionMapper = {};
