import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { type DependencyContainer } from 'tsyringe';
import { openCrossPrincipalFill } from '@app/generated/sdk';
import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { internalizationCommonFilters } from './grid-actions.utils';

export const isActionEnabled = (
  appContainer: DependencyContainer,
  data: VisibleInvestorOrderInfoWithAllocationsFragment[]
) => {
  if (data.length !== 1) {
    return false;
  }

  const authSerive = appContainer.resolve(AuthService);
  const userId = authSerive.getUserId();

  return internalizationCommonFilters(data[0], userId);
};

export const openCrossPrincipalFillAction: ActionDefFactory<
  VisibleInvestorOrderInfoWithAllocationsFragment
> = (builder) =>
  builder
    .name('open_cross_principal_fill')
    .toolbar((t) =>
      t.id('cross_principal_fill').component('action-button').location('HorizontalToolbarRight').props({
        content: 'Cross',
        isDisabled: true
      })
    )
    .customMenu((m) =>
      m.name('Cross').visible(({ rowData, appContainer }) => isActionEnabled(appContainer, rowData))
    )
    .onChange(async (e) => {
      const { lifecycle, data, appContainer } = e;

      e.notify({ isDisabled: !isActionEnabled(appContainer, data) });

      if (lifecycle === 'change') {
        await openCrossPrincipalFill(PROCESS_ID.LEADER, {
          form: {
            input: {
              id: data[0].id
            }
          }
        });
      }
    });
