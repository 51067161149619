import { Plugin } from '@valstro/workspace';
import { type AppWorkspace } from '@app/app-config/workspace.config';
import { type DependencyContainer } from 'tsyringe';
import { RxdbReplicationService } from '@app/data-access/offline/rxdbReplicationService';
import { createWorkspaceLeaderWindowReady$ } from '@app/common/workspace/workspace.rxjs';
import { combineLatest } from 'rxjs';
import { AppState } from '@app/data-access/memory/app.stream';

export interface SyncronisationPluginOptions {
  container: DependencyContainer;
}

export const syncronisationPlugin = ({ container }: SyncronisationPluginOptions) => {
  return Plugin.create<AppWorkspace>({
    name: 'valstro-syncronisation-plugin',
    pluginFn: ({ workspace }) => {
      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const appState = container.resolve(AppState);
      let replication: RxdbReplicationService | undefined;
      const subscription = combineLatest([leaderWindowReady$, appState.$]).subscribe(
        ([leaderWindowReady, appState]) => {
          if (leaderWindowReady.isLeader && appState.state === 'Connected' && appState.user) {
            replication = container.resolve(RxdbReplicationService);
            void replication.init();
            workspace.updateMeta({
              replication
            });
          }
        }
      );
      return () => {
        subscription.unsubscribe();
        if (replication) {
          replication.stopReplication();
        }
      };
    }
  });
};
