import { MatchedInvestorOrderSummary } from '@app/containers/matched-investor-orders/matched-investor-order-summary';
import { MatchedInvestorOrdersCheckboxGroup } from '@app/containers/matched-investor-orders/matched-investor-orders-checkbox-group';
import { OrderSide, type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { Flex, Text, type Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { useCallback, type FC } from 'react';
import { useEligibleOrders } from './useEligibleOrders.hook';

export const EligibleOrders: FC = () => {
  return (
    <Flex
      direction="column"
      sx={{
        backgroundColor: 'layout.level2',
        borderRadius: 'md',
        gap: 'medium'
      }}
    >
      <EligibleOrderGroup side={OrderSide.Buy} />
      <EligibleOrderGroup side={OrderSide.Sell} />
    </Flex>
  );
};

const EligibleOrderGroup = ({ side }: { side: OrderSide }) => {
  const { selectedOrders, setSelectedOrders, instrumentId } = useEligibleOrders();

  const handleSelectOrders = useCallback(
    (checkedOrders: VisibleInvestorOrderInfoWithAllocationsFragment[]) => {
      setSelectedOrders(checkedOrders);
    },
    [setSelectedOrders]
  );

  const textColor: Sprinkles['color'] = side === OrderSide.Buy ? 'Green.400' : 'Red.400';

  return (
    <Flex direction="column" sx={{ gap: 'small' }}>
      <Flex justify="space-between" sx={{ width: 'full' }}>
        <Text sx={{ color: textColor }}>{side.toUpperCase()}</Text>
        <Flex sx={{ paddingInlineStart: 'small' }}>
          <MatchedInvestorOrderSummary orders={selectedOrders} />
        </Flex>
      </Flex>
      {instrumentId && (
        <Flex
          sx={{
            backgroundColor: 'layout.level2',
            borderRadius: 'md',
            justifyContent: 'space-between',
            gap: 'small',
            alignItems: 'center'
          }}
        >
          <MatchedInvestorOrdersCheckboxGroup
            instrumentId={instrumentId}
            side={side}
            placement="montage"
            handleSelectOrders={handleSelectOrders}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default EligibleOrders;
