import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import {
  FormBuilderRenderer,
  useInterpretRemoteFormDefinition,
  type RemoteFormBuilderDefinition
} from '@oms/frontend-foundation';
import { memo, useRef } from 'react';

export const MemoizedFormWidgetComponent = memo((props: RemoteFormBuilderDefinition) => {
  const rendererProps = useInterpretRemoteFormDefinition(props); // TODO: This re-renders too many times (including `useCurrentAppWidgetActorProps`) - try clicking somewhere in the form.
  return <FormBuilderRenderer {...rendererProps} />;
});

export const RemoteFormWidgetComponent: React.FC = () => {
  const [definition] = useCurrentAppWidgetActorProps<RemoteFormBuilderDefinition>();
  const defRef = useRef(definition);
  return <MemoizedFormWidgetComponent {...defRef.current} />;
};

export default RemoteFormWidgetComponent;
