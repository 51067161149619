import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';
import type { CancelInvestorOrdersInput, FeedbackWrapper } from '@oms/generated/frontend';
import { convertAlert, useWorkspaceContainer, type FoundationWorkspace } from '@oms/frontend-foundation';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import type { DependencyContainer } from 'tsyringe';
import { useCallback } from 'react';
import { useAppWorkspace, useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';

/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the CancelOrders mutation, and handle any failures by recursion.
 */
async function openConfirmationAndListen(
  orderIds: string[],
  errors: FeedbackWrapper[],
  orderService: InvestorOrdersService,
  workspace: FoundationWorkspace,
  numRows: number,
  windowId: string
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, windowId, {
    title: `Cancel ${numRows} investor order${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} investor order${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelInvestorOrdersInput = {
        investorOrderIds: orderIds
      };

      const resp = await orderService.cancelOrders(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(
          orderIds,
          feedbackWrappers,
          orderService,
          workspace,
          numRows,
          windowId
        );
      }

      break;
    }
  }
}

export interface IOpenCancelInvestorOrderDialogOptions {
  workspace: FoundationWorkspace;
  container: DependencyContainer;
  windowId: string;
  investorOrderIds: string[];
}

export const openCancelInvestorOrderDialog = async ({
  workspace,
  container,
  windowId,
  investorOrderIds
}: IOpenCancelInvestorOrderDialogOptions) => {
  const orderService = container.resolve(InvestorOrdersService);

  const [_, api] = await openConfirmation(workspace, windowId, {
    title: `Cancel ${investorOrderIds.length} investor order${investorOrderIds.length > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      message: `Are you sure you want to cancel ${investorOrderIds.length} investor order${
        investorOrderIds.length > 1 ? 's' : ''
      }?`,
      confirmButtonText: t('app.common.yes'),
      cancelButtonText: t('app.common.no')
    }
  });
  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      // By the time the user clicks past the confirmation dialog, the count of selected rows
      // that are active may have changed.

      const input: CancelInvestorOrdersInput = {
        investorOrderIds: investorOrderIds
      };
      const resp = await orderService.cancelOrders(input);

      if (resp.isSuccess() === false) {
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

        await openConfirmationAndListen(
          investorOrderIds,
          feedbackWrappers,
          orderService,
          workspace,
          investorOrderIds.length,
          windowId
        );
      }

      break;
    }
  }
};

export const useOpenCancelInvestorOrderDialog = (id: string) => {
  const workspace = useAppWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentAppWindow();

  return useCallback(() => {
    openCancelInvestorOrderDialog({
      workspace: workspace,
      container: container,
      windowId: windowActor.id,
      investorOrderIds: [id]
    }).catch((e) => {
      console.error(e);
    });
  }, [workspace, id, container, windowActor.id]);
};
