import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { type TradeInfoFragment, TradeStatus, type CancelTradesInput } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { type FoundationWorkspace, convertAlert } from '@oms/frontend-foundation';
import { type FeedbackWrapper } from '@oms/frontend-foundation';

/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the CancelTrades mutation, and handle any failures by recursion.
 */
async function openConfirmationAndListen(
  tradeIds: string[],
  errors: FeedbackWrapper[],
  tradeService: TradesService,
  workspace: FoundationWorkspace,
  numRows: number
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
    title: `Cancel ${numRows} trade${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} trade${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelTradesInput = {
        ids: tradeIds
      };

      const resp = await tradeService.cancelTrades(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(tradeIds, feedbackWrappers, tradeService, workspace, numRows);
      }

      break;
    }
  }
}

function getActiveRowsLength(data: TradeInfoFragment[]) {
  return data.filter((selectedRow) => selectedRow.status === TradeStatus.Active).length;
}

function isVisible(rowData: TradeInfoFragment[]): boolean {
  return getActiveRowsLength(rowData) > 0;
}

export const cancelTradeOnChange = async (
  ctx: ActionContext<TradeInfoFragment, ActionComponentConfig<TradeInfoFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;

  const activeRows = getActiveRowsLength(data);

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (activeRows === 0) {
      return;
    }

    const tradesService = ctx.appContainer.resolve(TradesService);

    const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
      title: `Cancel ${activeRows} trade${activeRows > 1 ? 's' : ''}`,
      componentProps: {
        autoClose: true,
        message: `Are you sure you want to cancel ${activeRows} trade${activeRows > 1 ? 's' : ''}?`,
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      }
    });
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        // By the time the user clicks past the confirmation dialog, the count of selected rows
        // that are active may have changed.
        const activeTradeIds = data
          .filter((selectedRow) => selectedRow.status === TradeStatus.Active)
          .map((row) => row.id);

        const input: CancelTradesInput = {
          ids: activeTradeIds
        };

        const resp = await tradesService.cancelTrades(input);

        if (resp.isSuccess() === false) {
          const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

          await openConfirmationAndListen(
            activeTradeIds,
            feedbackWrappers,
            tradesService,
            workspace,
            activeTradeIds.length
          );
        }

        break;
      }
    }
  }
};

export const cancelTradeAction: ActionDefFactory<TradeInfoFragment> = (builder) =>
  builder
    .name('cancel_trade')
    .toolbar((t) =>
      t.component('action-button').id('cancel_trade_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Cancel'
      })
    )
    .customMenu((m) => m.name('Cancel').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TradeInfoFragment>>(cancelTradeOnChange);
