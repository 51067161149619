import { type IoRepairRequestFragment } from '@oms/generated/frontend';
import {
  useVGrid,
  VGrid,
  toClientsideGridData,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { InvestorOrderRepairRequestService } from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import { ioRepairRequestColumnLibrary } from './repair-queue.investor-orders.columns';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { useRef } from 'react';
import { groupByInvestorOrdersAction } from './grid-actions/group-by.repair-queue.investor-orders.action';
import { rejectRepairQueueInvestorOrdersAction } from './grid-actions/reject.repair-queue.investor-orders.action';
import { retryInvestorOrdersAction } from './grid-actions/retry.investor-orders.action';
import { repairInvestorOrdersAction } from './grid-actions/repair.investor-orders.action';
import { repairQueueInlineActions } from './grid-actions/inline.repair-queue.investor-orders.action';

export const REPAIR_QUEUE_INVESTOR_ORDERS = 'repair-queue-investor-orders';

export const RepairQueueInvestorOrdersGridWidget = () => {
  const datasourceService = useService(InvestorOrderRepairRequestService);
  const query$ = useRef(datasourceService.watchAll$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<IoRepairRequestFragment>(
    REPAIR_QUEUE_INVESTOR_ORDERS,
    (b) =>
      b
        .columnLibrary(ioRepairRequestColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data?.id as string))
        .rowSelection((c) => c.multiple())
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(groupByInvestorOrdersAction)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(retryInvestorOrdersAction)
                .action(repairInvestorOrdersAction)
                .action(repairQueueInlineActions)
                .action(rejectRepairQueueInvestorOrdersAction)
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default RepairQueueInvestorOrdersGridWidget;
