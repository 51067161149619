import { Plugin, isTauri } from '@valstro/workspace';
import { type AppWorkspace } from '@app/app-config/workspace.config';
import type {
  OfflineDatabase,
  OfflineDatabasePluginOptions
} from '@app/data-access/offline/offline-database';
import {
  OfflineDb,
  addOfflineDatabaseCollections,
  createOfflineDatabase,
  destroyAndClearDatabase
} from '@app/data-access/offline/offline-database';
import { createLogger } from '@oms/shared/util';
import type { RxStorage } from 'rxdb-v15';
import { confirm as tauriConfirm } from '@tauri-apps/api/dialog';
import { relaunch, exit } from '@tauri-apps/api/process';
import { OfflineDatabaseSignal } from '@app/data-access/memory/offline-database.signal';
import type { DependencyContainer } from 'tsyringe';

export const offlineDatabasePluginLogger = createLogger({ label: 'Offline Database Plugin' });

/**
 * Creates the app database when the leader window is ready
 */
export const offlineDatabasePlugin = ({
  container,
  ...options
}: OfflineDatabasePluginOptions & { container: DependencyContainer }) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-app-database-plugin',
    pluginFn: ({ workspace }) => {
      const signalService = container.resolve(OfflineDatabaseSignal);
      let offlineDb: OfflineDb | undefined;
      const unsubWindowReady = workspace.addHook('leaderElection', async ({ isLeader }) => {
        if (offlineDb) {
          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: offlineDb,
            error: null
          });
          return;
        }

        try {
          signalService.signal.set({
            isConnecting: true,
            isReady: false,
            error: null
          });

          const existingOfflineDatabase = workspace.getMeta().offlineDatabase;
          if (existingOfflineDatabase) {
            return;
          }
          const { db: offlineDatabase, storage } = await createOfflineDatabase(options);
          try {
            await addOfflineDatabaseCollections(offlineDatabase);
          } catch (e) {
            console.error(e);
            signalService.signal.set({
              isConnecting: true,
              isReady: false,
              error: (e as Error)?.message
            });
            if (isTauri()) {
              await handleTauriRxDBError(offlineDatabase, storage);
            } else {
              await handleBrowserRxDBError(e, offlineDatabase, storage);
            }
          }

          offlineDb = new OfflineDb(offlineDatabase);
          workspace.updateMeta({
            offlineDatabase
          });
          const container = workspace.meta?.container;
          if (!container) {
            throw new Error('Container not found');
          }
          container.register(OfflineDb, { useValue: offlineDb });
          offlineDatabasePluginLogger.log('Created & registered offline database', offlineDb);

          if (isLeader) {
            // Prune database on startup (remove orphaned grids and grid templates)
            pruneDatabase().catch(console.error);
          }

          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: offlineDb,
            error: null
          });
        } catch (e) {
          console.error(e);
          signalService.signal.set({
            isConnecting: false,
            isReady: false,
            error: (e as Error)?.message
          });
        }
      });

      const unsubscribe = async () => {
        unsubWindowReady();
        if (offlineDb) {
          await offlineDb.db.destroy();
          offlineDatabasePluginLogger.log('Destroyed offline database');
        }

        signalService.reset();

        offlineDb = undefined;
      };

      return unsubscribe;
    }
  });

async function handleTauriRxDBError(offlineDatabase: OfflineDatabase, storage: RxStorage<any, any>) {
  const confirmed = await tauriConfirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    await relaunch();
  } else {
    await exit(1);
  }
}

async function handleBrowserRxDBError(
  e: unknown,
  offlineDatabase: OfflineDatabase,
  storage: RxStorage<any, any>
) {
  // eslint-disable-next-line no-restricted-globals
  const confirmed = confirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    window.location.reload();
  } else {
    throw e;
  }
}

/**
 * Prunes the database by removing orphaned grids and grid templates
 */
async function pruneDatabase() {
  // Prune orphaned stuff here if need be
}
