import { FormValidationFeedbackLevel, NotificationResourceTemplates, Priority, ResourceType, VisibilityReason } from '@oms/generated/frontend';
import type { Notification } from './notifications.contracts';

export const allAlertsMockData: Notification[] = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    name: NotificationResourceTemplates.IoNew,
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: '',
    visibilityReason: VisibilityReason.UserPrimary,
    resourceFields: {
      account: 'Blackrock',
      instrument: 'MSFT-US',
      side: 'Buy',
      quantity: '1112',
      price: '89.45'
    },
    feedbackMetadata: [
      {
        code: 'ERR_OM_AccountCreditLimit_SingleOrderQuantity',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Quantity Limit of 10,000 for Blackrock',
      },
      {
        code: 'ERR_OM_AccountCreditLimit_SingleOrderValue',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Value Limit of 50,000 for Blackrock',
      },
      {
        code: 'WARN_OM_AccountCreditLimit_DailyOrderValue',
        level: FormValidationFeedbackLevel.Warning,
        message: 'Exceeds Daily Order Value of 1,000,000 for Blackrock',
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: '00000000-0000-0000-0000-000000000001'
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    name: NotificationResourceTemplates.IoNew,
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: '',
    visibilityReason: VisibilityReason.AccountBackup,
    resourceFields: {
      account: 'Dreyfus',
      instrument: 'AAPL-US',
      side: 'Sell',
      quantity: '11',
      price: '11.99'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: '00000000-0000-0000-0000-000000000002'
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    name: NotificationResourceTemplates.IoNew,
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: '',
    visibilityReason: VisibilityReason.UserTeamMember,
    resourceFields: {
      account: 'Neuberger Berman',
      instrument: 'GOOGL-US',
      side: 'Buy',
      quantity: '1001',
      price: '11.23'
    },
    feedbackMetadata: [
      {
        code: 'ERR_OM_AccountCreditLimit',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Quantity Limit',
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: '00000000-0000-0000-0000-000000000003'
  },
  {
    id: '00000000-0000-0000-0000-000000000004',
    name: NotificationResourceTemplates.IoExecution,
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: '',
    visibilityReason: VisibilityReason.Owner,
    resourceFields: {
      side: 'Buy',
      instrument: 'TSLA-US',
      quantity: '1000',
      price: '1000.00',
      tradeCounterparty: 'GSCO'
    },
    feedbackMetadata: [
      {
        code: 'WARN_OM_CapitalThreshold',
        level: FormValidationFeedbackLevel.Warning,
        message: 'Capital threshold exceeds 50%',
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: '00000000-0000-0000-0000-000000000004'
  }
];