import { BehaviorSubject, Subscription } from 'rxjs';
import { Lifecycle, scoped } from 'tsyringe';
import { EventHandler, GridEvent, EventSource } from '@oms/frontend-vgrid';
import { ApplyColumnStateParams, GridApi } from '@ag-grid-community/core';
import { type IOVisKey, IO_VIS_KEY, IO_VIS_VALUE } from '@app/widgets/trading/investor-order-monitor/grid-services/investor-order-monitor.visibility.filters';
import type { SortType } from '../notifications.widget';

export function createNotificationsEventHandler(
  variant$: BehaviorSubject<IOVisKey>, sort$: BehaviorSubject<SortType>
) {
  @scoped(Lifecycle.ContainerScoped)
  class NotificationsEventHandler implements EventHandler {
    private _api: GridApi | null = null;
    public name = 'notifications-event-handler';
    private subscriptions: Subscription[] = [];

    constructor() {}

    public addEvents(eventSource: EventSource<keyof GridEvent>): void {
      eventSource.add('onGridReady', (e) => {
        this._api = e.api;

        this.subscriptions.push(
          sort$.subscribe((e) => {
            this.api.resetColumnState();

            const updatedColumnState: ApplyColumnStateParams =
              e === 'priority'
                ? {
                    // When the sort order is "Priority", sort firstly by priority, secondly by createdAt.
                    state: [
                      { colId: 'priority', sort: 'asc', sortIndex: 0 },
                      { colId: 'createdAt', sort: 'desc', sortIndex: 1 }
                    ]
                  }
                : {
                    // When the sort order is "Event", sort firstly by priority, secondly by name, thirdly by createdAt.
                    state: [
                      { colId: 'priority', sort: 'asc', sortIndex: 0 },
                      { colId: 'name', sort: 'asc', sortIndex: 1 },
                      { colId: 'createdAt', sort: 'desc', sortIndex: 2 }
                    ]
                  };

            this.api.applyColumnState(updatedColumnState);
          })
        );

        this.subscriptions.push(
          variant$.subscribe((selectedKey: IOVisKey) => {
            const currentFilters = this.api.getFilterModel();
              this.api.setFilterModel({
                ...currentFilters,
                'visibilityReason':
                  selectedKey === IO_VIS_KEY.ALL
                    ? undefined
                    : {
                        type: 'set',
                        values: IO_VIS_VALUE[selectedKey]
                      }
              });

          })
        );
      });
    }

    public removeEvents(): void {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];
    }

    private get api(): GridApi {
      if (!this._api) {
        throw new Error('Grid API is not set');
      }
      return this._api;
    }
  }

  return NotificationsEventHandler;
}
