import {
  type ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  type SplitButtonActionComponentConfig
} from '@oms/frontend-vgrid';
import { ModificationStatus, type VisibleModificationInfoFragment } from '@oms/generated/frontend';
import { acceptPendingModificationOnChange } from './accept.pending-modification.action';
import { rejectPendingModificationOnChange } from './reject.pending-modification.action';

export const inlinePendingModificationsActions: ActionDefFactory<VisibleModificationInfoFragment> = (
  builder
) =>
  builder
    .name('inline_pending_modification_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(110)
        .filter(false)
        .cell((c) =>
          c.renderer(INLINE_SPLIT_BUTTON_ACTION, {
            actionsConfig: [
              {
                id: 'accept',
                title: 'Accept'
              },
              {
                id: 'reject',
                title: 'Reject'
              }
            ]
          })
        )
    )
    .lifecycles('change', 'init')
    .onChange<SplitButtonActionComponentConfig<VisibleModificationInfoFragment>>(async (ctx) => {
      const { state, lifecycle } = ctx;

      const selectedId = state.selectedId;
      const selectedRow = ctx.data[0];

      const isAcceptingStatus =
        selectedRow &&
        'status' in selectedRow &&
        selectedRow?.status === ModificationStatus.Accepting &&
        selectedRow?.__typename === 'VisibleModification';

      switch (lifecycle) {
        case 'init': {
          ctx.notify({ isDisabled: isAcceptingStatus });
          break;
        }

        case 'change': {
          switch (selectedId) {
            case 'accept':
              return await acceptPendingModificationOnChange(ctx);
            case 'reject':
              return await rejectPendingModificationOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${selectedId || 'selectedId'}`);
          }
        }
      }
    });
