import type {
  OrderRouteCreateInput,
  ModifyTradingOrderInput,
  OrderSideType,
  VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment,
  VisibleTradingOrderInfoWithAllocationsFragment as TOFragment,
  InvestorAccount,
  RequestOrderRouteMutationVariables
} from '@oms/generated/frontend';
import type { RouteOrderFormInputSchema } from './route-order.form-builder.config';
import type { CreateTradingOrderOutput } from '@app/common/types/orders/orders.types';
import type { PriceInputValue } from '@oms/frontend-foundation';

export const ROUTE_ORDER_FORM_TYPE = {
  CREATE: 'create',
  MODIFY: 'modify',
  ROUTE: 'route'
} as const;

export type RouteOrderFormType = typeof ROUTE_ORDER_FORM_TYPE[keyof typeof ROUTE_ORDER_FORM_TYPE];

export const ROUTE_ORDER_FORM_LOCATION_TYPE = {
  MONTAGE: 'montage'
} as const;

export type RouteOrderFormLocationType =
  typeof ROUTE_ORDER_FORM_LOCATION_TYPE[keyof typeof ROUTE_ORDER_FORM_LOCATION_TYPE];

export type RouteOrderFormInput = RouteOrderFormInputSchema;

export type RouteOrderFormInfo =
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['ROUTE'];
      investorOrder: IOFragment;
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['MODIFY'];
      tradingOrder: TOFragment;
      location?: RouteOrderFormLocationType;
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['CREATE'];
      location?: RouteOrderFormLocationType;
    };

export type RouteOrderMode =
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['ROUTE'];
      investorOrderId: string;
      investorOrderType?: IOFragment['orderType'];
      investorOrderLimitPrice?: IOFragment['limitPrice'];
      investorOrderQuantity?: IOFragment['quantity'];
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['MODIFY'];
      tradingOrderId: string;
      location?: RouteOrderFormLocationType;
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['CREATE'];
      location?: RouteOrderFormLocationType;
    };

export type RouteOrderFieldContract = {
  // hidden fields to store useful information
  hiddenMode: RouteOrderMode;
  hiddenStrategyOptions: string[];
  // blank fields
  display: string;
  strategyPreset: string;
  // visible fields
  instrument: string;
  venue: string;
  sideType: OrderSideType;
  quantity: number;
  timeInForce: OrderRouteCreateInput['timeInForce'];
  locate: string;
  limitPrice?: PriceInputValue;
  // route specific fields
  trader: string;
  customerNotes: OrderRouteCreateInput['customerNotes'];
  venueNotes: OrderRouteCreateInput['venueNotes'];
  tradeCurrency: string;
  settlementType: IOFragment['settleType'];
  settlementDate: string;
  orderTags: IOFragment['orderTags'];
  strategy: OrderRouteCreateInput['strategy'];
  // createTO specific fields
  tradingOrderCategory: TOFragment['category'];
  matchedInvestorOrderIds: string[];
  firmAccount: InvestorAccount;
};

export type RouteInvestorOrderOutput = Pick<RequestOrderRouteMutationVariables, 'orderRoute' | 'priceOption'>;

export type RouteOrderFormOutput =
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['CREATE'];
      output: CreateTradingOrderOutput;
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['MODIFY'];
      output: ModifyTradingOrderInput;
    }
  | {
      type: typeof ROUTE_ORDER_FORM_TYPE['ROUTE'];
      output: RouteInvestorOrderOutput;
    };
