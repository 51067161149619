import { type CustomMenuItemProps } from '@ag-grid-community/react';
import {
  type MontageItemType,
  type MontageItem
} from '@app/data-access/services/trading/montage/montage.types';
import { MontageContextMenuItemTitle } from './title';
import { optionStyle, ulStyle, wrapperStyle } from './montage-contextmenu.css';
import { useMontageContextMenuItem } from '../hooks/useMontageContextMenuItem.hook';
import { Option } from './montage-contextmenu-option';
import { OrderType } from '@oms/generated/frontend';
import { container } from 'tsyringe';
import { TradingOrdersService } from '@app/data-access/services/trading/trading-orders/trading-orders.service';
import { openMontageModifyTradingOrder } from '@app/widgets/trading/route-order/route-order.form-open';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';

type MontageCustomMenuItemProps = CustomMenuItemProps & {
  type: MontageItemType;
};

export const MontageContextMenuItem = ({ name, type }: MontageCustomMenuItemProps) => {
  const { filteredOrders } = useMontageContextMenuItem({ type });

  if (name !== 'Modify' && name !== 'Cancel') {
    throw new Error('Invalid context menu item name');
  }

  return (
    <div className={wrapperStyle}>
      <MontageContextMenuItemTitle name={name} />

      <ul className={ulStyle}>
        {filteredOrders?.length
          ? filteredOrders.map((order) => <ModifyOrCancelOption key={order.id} name={name} order={order} />)
          : '-'}
      </ul>
    </div>
  );
};

async function handleOptionClick({
  orderId,
  name,
  workspace
}: {
  workspace: AppWorkspace | FoundationWorkspace;
  orderId: string;
  name: 'Modify' | 'Cancel';
}) {
  if (name === 'Modify') {
    openMontageModifyTradingOrder(workspace, {
      id: orderId
    });
  }

  if (name === 'Cancel') {
    const tradingOrderService = container.resolve(TradingOrdersService);
    await tradingOrderService.cancel(orderId);
  }
}

const ModifyOrCancelOption = ({ order, name }: { order: MontageItem; name: 'Modify' | 'Cancel' }) => {
  const workspace = useAppWorkspace();
  const { size, orderType, type } = order;

  const formattedOrderType = type[0]?.toUpperCase(); // Bid -> B, Ask -> A

  const formattedPrice = orderType === OrderType.Limit ? order.limitPrice : 'MKT';

  return (
    <Option
      className={optionStyle}
      onClick={() => void handleOptionClick({ orderId: order.id, name, workspace })}
    >
      {formattedOrderType} {size} @ {formattedPrice}
    </Option>
  );
};
