import { useClosestAppFlexLayoutActor } from '@app/common/workspace/workspace.hooks';
import { MontageService } from '@app/data-access/services/trading/montage/montage.service';
import { type MontageItemType } from '@app/data-access/services/trading/montage/montage.types';
import { useService } from '@oms/frontend-foundation';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';

export const useMontageContextMenuItem = ({ type }: { type: MontageItemType }) => {
  const flexLayoutActorId = useClosestAppFlexLayoutActor()?.id;

  const montageService = useService(MontageService);

  const montageUnboundTradingOrders$ = useMemo(
    () => montageService.watchAllMontageUnboundTradingOrders$(flexLayoutActorId || ''),
    [montageService, flexLayoutActorId]
  );

  const unfilteredOrders = useObservableState(montageUnboundTradingOrders$);

  const filteredOrders = unfilteredOrders?.results?.filter((order) => order.type === type);

  return { filteredOrders };
};
