import { t } from 'i18next';
import {
  type ValidateDurationInputOptions,
  validateDurationInput
} from '../validate-duration-input/validate-duration-input.validator';
import type { MultiSelectValue } from '@oms/frontend-foundation';
import { TimeInForce } from '@oms/generated/frontend';

export const validateTifInput =
  (options: ValidateDurationInputOptions) =>
  (value: MultiSelectValue<TimeInForce>, allValues: Record<string, any>) => {
    if (!value) {
      return t('app.common.requiredField');
    }

    if (typeof value?.subValue === 'string') {
      if (value.id === TimeInForce.Duration) {
        let foundKey;
        for (const key of ['tifDuration', 'bidTifDuration', 'askTifDuration']) {
          if (allValues[key]) {
            foundKey = key;
          }
        }

        if (foundKey) {
          const result = validateDurationInput(options)(allValues[foundKey]);

          if (result) {
            return result;
          }
        }
      }
    }
  };
