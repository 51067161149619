import {
  type CommonToolbarActionsTransformer,
  WindowDecorationComponent as Window,
  type CommonToolbarAction
} from '@valstro/workspace-react';
import { useCallback, type PropsWithChildren } from 'react';
import { PopInButton } from './common/toolbar-actions/pop-in.action';
import { ActorInfo } from './common/toolbar-actions/actor-info.action';
import { getEnvVar } from '../common/env/env.util';
import { useVisibleToolbarActions } from '@app/common/toolbar/hooks/use-visible-toolbar-actions.hook';
import { ErrorBoundary } from '@app/containers/error-boundary/error-boundary.container';
import { useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';

export function AppWindowWidgetDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const windowActor = useCurrentAppWindow();
  const toolbarActions = useVisibleToolbarActions('window');

  const actionsTransformer: CommonToolbarActionsTransformer = useCallback(
    (actions) => {
      const windowToolbarActions = toolbarActions.map(({ id, component: Component }) => ({
        type: id,
        component: <Component key={id} windowActor={windowActor} />
      }));
      const nextActions: CommonToolbarAction[] = [
        {
          type: 'popin',
          component: <PopInButton key="popin" />
        },
        ...windowToolbarActions,
        ...actions
      ];
      if (getEnvVar('NODE_ENV') === 'development') {
        return [<ActorInfo key="actor-info" />, ...nextActions];
      }
      return nextActions;
    },
    [toolbarActions, windowActor]
  );

  return (
    <Window.Container {...decorationProps}>
      <Window.Toolbar>
        <ErrorBoundary>
          <Window.ToolbarTitlebar>
            <Window.ToolbarTitlebarTitle />
          </Window.ToolbarTitlebar>
          <Window.ToolbarActionsContainer>
            <Window.ToolbarActions actionsTransformer={actionsTransformer} />
          </Window.ToolbarActionsContainer>
        </ErrorBoundary>
      </Window.Toolbar>
      <Window.ContentContainer>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Window.ContentContainer>
    </Window.Container>
  );
}

export function LauncherWindowWidgetDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <Window.Container {...decorationProps}>
      <Window.ContentContainer>{children}</Window.ContentContainer>
    </Window.Container>
  );
}
