import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import { sharedDefaultCol, sharedIdCol } from '@app/common/grids/columns/generic-cols';
import type { Notification } from './notifications.contracts';
import { NotificationCellRenderer } from './notifications.cell-renderers';
import { type ColDef } from '@ag-grid-community/core';
import { Priority } from '@oms/generated/frontend';
import { getDisplayTemplateName } from './notifications.utils';

type notificationsColumnBuilder = ColumnBuilderCallback<Notification>;

const defaultCol: notificationsColumnBuilder = (c) => sharedDefaultCol(c).filter(false).sortable(true);

const contentCol: notificationsColumnBuilder = (c) =>
  c
    .field('resourceFields') // pointing to the field which keeps all needed info for the notification
    .cell(
      (c) => c.renderer(NotificationCellRenderer)
      // TODO: Implement when fanalizing the contract
      // .valueGetter((p) => ({
      //   // Making the value an object equal to the properties which need to trigger a re-render in the cell renderer
      //   content: p.data?.content,
      //   isRead: p.data?.isRead,
      //   isSeen: p.data?.isSeen
      // }))
    )
    .flex(1);

const priorityComparator: ColDef['comparator'] = (valueA: Priority, valueB: Priority) => {
  const order = [Priority.Top, Priority.High, Priority.Normal];
  return order.indexOf(valueA) - order.indexOf(valueB);
};

// Unhide columns for dev
const hidden = true;

const idCol: notificationsColumnBuilder = (c) => sharedIdCol(c, 'id').hide(hidden);
const priorityCol: notificationsColumnBuilder = (c) =>
  c.field('priority').sortable(true).comparator(priorityComparator).resizable(false).hide(hidden);
const resourceTypeCol: notificationsColumnBuilder = (c) => c.field('targetObjectType').hide(hidden);
const nameCol: notificationsColumnBuilder = (c) =>
  c
    .field('name')
    .cell((c) => c.valueFormatter(({ value }) => getDisplayTemplateName(value as string)))
    .hide(hidden);
const createdAtCol: notificationsColumnBuilder = (c) => c.field('createdAt').hide(hidden);
const visibilityReasonCol: notificationsColumnBuilder = (c) =>
  c.field('visibilityReason').filter(true).hide(hidden);

export const notificationsColumnLibrary: ColumnLibrary<Notification> = {
  defaultColumn: defaultCol,
  columns: [idCol, resourceTypeCol, nameCol, contentCol, priorityCol, createdAtCol, visibilityReasonCol]
};
