import { inject, singleton } from 'tsyringe';
import type { RegisterCommand } from '../register-command.model';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { CommandPaletteItem } from '@app/common/command-palette/command-palette.contracts';
import { PROCESS_ID, isTauri } from '@valstro/workspace';
import type { SignalUnsubscribeFn } from '@oms/shared-frontend/rx-broadcast';
import { COMMAND_PALETTE_GROUP } from '@app/commands/command-palette.constants';
import { OfflineDb } from '@app/data-access/offline/offline-database';
import { reflow$ } from '@app/workspace/plugin-adaptive-layouts/adaptive-layouts.plugin';
import { exitTauriApp } from '@app/tauri/util';
import { openAboutValstro } from '@app/generated/sdk';

@singleton()
export class SystemCommands implements RegisterCommand {
  private authUnsubscribe: SignalUnsubscribeFn | undefined = undefined;
  constructor(
    @inject(CommandPaletteService) private cmpd: CommandPaletteService,
    @inject(AuthService) private authService: AuthService,
    @inject(OfflineDb) private offlineDb: OfflineDb
  ) {}

  async init() {
    const systemItems: CommandPaletteItem[] = [
      // TODO: Doesn't work yet, would need to close windows first
      // {
      //   id: 'clear-remembered-windows',
      //   label: 'Clear Remembered Windows',
      //   category: COMMAND_PALETTE_GROUP.SYSTEM,
      //   onSelect: async () => {
      //     const docs = await this.offlineDb.collections.window_contexts.find().exec();
      //     await this.offlineDb.collections.window_contexts.bulkRemove(docs.map((doc) => doc.type));
      //   }
      // },
      {
        id: 'about',
        label: 'About Valstro',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          void openAboutValstro().catch(console.error);
        }
      },
      {
        id: 'reflow',
        label: 'Reflow windows',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          reflow$.next();
        }
      },
      {
        id: 'logout',
        label: 'Logout',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          this.authService.logout();
        }
      }
    ];

    if (isTauri()) {
      systemItems.push({
        id: 'logout-exit',
        label: 'Logout & exit',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: exitTauriApp
      });
    }

    await this.cmpd.register(systemItems);
  }

  dispose() {
    if (this.authUnsubscribe) {
      this.authUnsubscribe();
    }
  }

  private getWindowIdToOpenFrom(windowId: string) {
    return isTauri() ? PROCESS_ID.LEADER : windowId; // In browser we want windows to open in the root window
  }
}
