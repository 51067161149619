import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { DEFAULT_AUTH_STATE } from '../auth/auth.contracts';
import { type AuthClientState, type ParsedIdentityToken } from '../auth/keycloak.types';
import {
  DEFAULT_SYNCRONISATION_STATE,
  SyncronisationState
} from '@app/data-access/memory/syncronisation.signal';

export const APP_STATE_TYPE = {
  IDLE: 'Idle',
  AUTHENTICATING: 'Authenticating',
  UNAUTHORIZED: 'Unauthorized',
  DATA_ACCESS_CONNECTING: 'Connecting',
  DATA_ACCESS_CONNECTED: 'Connected',
  DATA_ACCESS_DISCONNECTED: 'Disconnected',
  SYNCRONISING: 'Syncronising',
  READY: 'Ready'
} as const;

export type AppStateType = typeof APP_STATE_TYPE[keyof typeof APP_STATE_TYPE];

export type AppState = {
  state: AppStateType;
  auth: AuthClientState;
  dataAccess: DataAccessState;
  user?: ParsedIdentityToken | null;
  syncronisation: SyncronisationState;
};

export const DEFAULT_APP_STATE: AppState = {
  state: APP_STATE_TYPE.IDLE,
  auth: DEFAULT_AUTH_STATE,
  dataAccess: {
    isReady: false,
    isConnected: false
  },
  syncronisation: DEFAULT_SYNCRONISATION_STATE
};
