import { z } from 'zod';
import {
  OrderType,
  OrderSideType,
  InvestorOrderStatus,
  OrderEntryType,
  TimeInForce,
  OrderSide,
  OrderSettleType,
  TradingOrderCategory
} from '@oms/generated/frontend';

const zOrderType: z.ZodType<OrderType> = z.enum([OrderType.Market, OrderType.Limit]);

const zOrderSideType: z.ZodType<OrderSideType> = z.enum([
  OrderSideType.Buy,
  OrderSideType.Sell,
  OrderSideType.Btc,
  OrderSideType.Exempt,
  OrderSideType.Short
]);

const zOrderSideOnlyType: z.ZodType<OrderSide> = z.enum([OrderSide.Buy, OrderSide.Sell]);

const zTimeInForce: z.ZodType<TimeInForce> = z.enum([
  TimeInForce.Close,
  TimeInForce.Open,
  TimeInForce.Day,
  TimeInForce.Duration,
  TimeInForce.Gtc,
  TimeInForce.Gtd,
  TimeInForce.Ioc,
  TimeInForce.TifNotSupported
]);

const zOrderEntryType: z.ZodType<OrderEntryType> = z.enum([OrderEntryType.Manual, OrderEntryType.Electronic]);

const zInvestorOrderStatus: z.ZodType<InvestorOrderStatus> = z.enum([
  InvestorOrderStatus.Active,
  InvestorOrderStatus.CancellationPending,
  InvestorOrderStatus.Cancelled,
  InvestorOrderStatus.Expirable,
  InvestorOrderStatus.Expired,
  InvestorOrderStatus.ExpiryPending,
  InvestorOrderStatus.FullyFilled,
  InvestorOrderStatus.Inactive,
  InvestorOrderStatus.Live,
  InvestorOrderStatus.ModificationPending,
  InvestorOrderStatus.ModifyRequested,
  InvestorOrderStatus.Rejected,
  InvestorOrderStatus.Unaccepted
]);

const zOrderSettlementType: z.ZodType<OrderSettleType> = z.enum([
  OrderSettleType.Cash,
  OrderSettleType.Future,
  OrderSettleType.NextDay,
  OrderSettleType.Regular,
  OrderSettleType.TPlus_2,
  OrderSettleType.TPlus_3,
  OrderSettleType.TPlus_4,
  OrderSettleType.TPlus_5,
  OrderSettleType.WhenIssued
]);

export const Z_INVESTOR_ORDER = {
  ORDER_TYPE: zOrderType,
  SIDE_TYPE: zOrderSideType,
  TIME_IN_FORCE: zTimeInForce,
  ORDER_ENTRY_TYPE: zOrderEntryType,
  INVESTOR_ORDER_STATUS: zInvestorOrderStatus,
  ORDER_SETTLEMENT_TYPE: zOrderSettlementType
} as const;

const zTradingOrderCategory: z.ZodType<TradingOrderCategory> = z.enum([
  TradingOrderCategory.Internal,
  TradingOrderCategory.Internalization,
  TradingOrderCategory.Mmpo,
  TradingOrderCategory.Montage,
  TradingOrderCategory.Standard,
  TradingOrderCategory.Iso
]);

export const Z_TRADING_ORDER = {
  CATEGORY: zTradingOrderCategory
} as const;

export const zOrderTitleDetailsSchema = z.object({
  sideType: zOrderSideType,
  quantity: z.number(),
  symbol: z.string(),
  price: z.union([z.number(), z.string()]), // Allow either number or string for price
  account: z.optional(z.string())
});

export const zTradeDescriptionSchema = z.object({
  side: zOrderSideOnlyType,
  quantity: z.number(),
  symbol: z.string(),
  price: z.union([z.number(), z.string()]), // Allow either number or string for price
  account: z.optional(z.string())
});
