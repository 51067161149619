import { useCallback } from 'react';
import {
  BorderNode,
  type TabSetNode,
  type ITabSetRenderValues,
  type Model,
  type Action,
  Actions
} from 'flexlayout-react';
import type { ReactFlexLayoutActorWrapperComp } from '@valstro/workspace-react';
import { Logger } from '@oms/shared/util';
import type { Maybe } from '@oms/shared/util-types';
import { Box } from '@oms/shared-frontend/ui-design-system';
import type { LayoutEnumKey } from '@app/generated/common';
import { useVisibleToolbarActions } from '@app/common/toolbar/hooks/use-visible-toolbar-actions.hook';
import { FlexLayoutToolbarProvider } from '@app/common/toolbar/toolbar-context';
import { type FlexLayoutUnion, emitTabEvent } from '@app/data-access/memory/flexlayout.events';
import useWindowMetaData from '@app/common/window/window.meta-data';
import useFlexLayoutPeripheralContent from './common/hooks/use-flex-layout-peripheral-content.hook';

export type FlexLayoutAction<
  TData extends Record<string, any> = Record<string, any>,
  Type extends string = string
> = Omit<Action, 'data' | 'type'> &
  Partial<{
    type: Type;
    data: TData;
  }>;

interface AnyTabActionData {
  tabNode?: string;
  node?: string;
  fromNode?: string;
  toNode?: string;
  location?: string;
  index?: number;
  select?: string;
}

type SelectTabActionData = Pick<AnyTabActionData, 'tabNode'>;

type MoveTabActionData = Pick<AnyTabActionData, 'fromNode' | 'toNode' | 'index' | 'location' | 'select'>;

type DeleteTabActionData = Pick<AnyTabActionData, 'node'>;

const l = Logger.labeled('FlayLayoutWrapper');

export const FlexLayoutWrapper: ReactFlexLayoutActorWrapperComp = ({ actor, FlexLayout }) => {
  const { widgetType } = useWindowMetaData();

  const Header = useFlexLayoutPeripheralContent('header', widgetType as LayoutEnumKey);
  const Footer = useFlexLayoutPeripheralContent('footer', widgetType as LayoutEnumKey);

  return (
    <FlexLayoutToolbarProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'full', width: 'full' }}>
        {Header}
        <FlayLayoutWrapperInternal actor={actor} FlexLayout={FlexLayout} />
        {Footer}
      </Box>
    </FlexLayoutToolbarProvider>
  );
};

export const FlayLayoutWrapperInternal: ReactFlexLayoutActorWrapperComp = ({ FlexLayout, actor }) => {
  const toolbarActions = useVisibleToolbarActions('flexlayout-tabset');

  const onRenderTabSet = useCallback(
    (tabSetNode: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
      if (tabSetNode instanceof BorderNode) return;
      renderValues.buttons = [
        ...toolbarActions
          .filter((t) => t.tabSetId === tabSetNode.getId())
          .map(({ component: Component, id }) => (
            <Component key={id} tabSetNode={tabSetNode} flexLayoutActor={actor} />
          )),
        ...renderValues.buttons
      ];
    },
    [toolbarActions, actor]
  );

  const onModelChange = useCallback(
    (model: Model, action: Action) => {
      try {
        const { type, data } = (action as Maybe<FlexLayoutAction<AnyTabActionData>>) || {};
        const selectedTabId = model.getActiveTabset()?.getSelectedNode()?.getId();
        const meta: FlexLayoutUnion['meta'] = { flexLayoutActorId: actor.id };
        switch (type) {
          case Actions.ADD_NODE: {
            emitTabEvent('ON_ADDED_TAB', { selectedTabId }, meta);
            break;
          }
          case Actions.SELECT_TAB: {
            const selected = (data as Maybe<SelectTabActionData>)?.tabNode || selectedTabId;
            emitTabEvent('ON_SELECT_TAB', { selectedTabId: selected }, meta);
            break;
          }
          case Actions.MOVE_NODE: {
            const fromId = (data as Maybe<MoveTabActionData>)?.fromNode;
            const toId = (data as Maybe<MoveTabActionData>)?.toNode;
            emitTabEvent('ON_MOVE_TAB', { selectedTabId, fromId, toId }, meta);
            break;
          }
          case Actions.DELETE_TAB: {
            const deletedTabId = (data as Maybe<DeleteTabActionData>)?.node;
            emitTabEvent('ON_DELETE_TAB', { selectedTabId, deletedTabId }, meta);
            break;
          }
        }
      } catch (e) {
        l.error(e);
      }
    },
    [actor.id]
  );

  return <FlexLayout onRenderTabSet={onRenderTabSet} onModelChange={onModelChange} />;
};
