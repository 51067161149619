import { DateFilter, FloatingDateFilter } from '../filters/date-filter';
import type { CustomFilterProps, CustomFloatingFilterProps } from '@ag-grid-community/react';
import type { AgGridStringFilter } from '../filters/filter-builders.relay';
import { EnrichedColumnDef } from './table-server.types';

const customFilterComponents = ['dateFilter'] as const;
const floatingFilterComponents = ['dateFilter'] as const;

export type CustomFilterComponent = typeof customFilterComponents[number];
export type FloatingFilterComponent = typeof floatingFilterComponents[number];

export type FilterComponent = CustomFilterComponent | AgGridStringFilter;

export const isCustomFilterComponent = (
  value: EnrichedColumnDef['filter']
): value is CustomFilterComponent => {
  return customFilterComponents.includes(value as CustomFilterComponent);
};

export const isFloatingFilterComponent = (
  value: EnrichedColumnDef['floatingFilterComponent']
): value is FloatingFilterComponent => {
  return floatingFilterComponents.includes(value as FloatingFilterComponent);
};

export type CustomFilterFn = (params: CustomFilterProps) => JSX.Element;
export type CustomFloatingFilterFn = (params: CustomFloatingFilterProps) => JSX.Element;

export const FLOATING_FILTER_COMPONENT_REGISTRY: Record<FloatingFilterComponent, CustomFloatingFilterFn> = {
  dateFilter: FloatingDateFilter
};

export const FILTER_COMPONENT_REGISTRY: Record<CustomFilterComponent, CustomFilterFn> = {
  dateFilter: DateFilter
};
