import { isEqual } from 'lodash';
import React, { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type TimePickerFieldProps } from '../../fixatdl-form.types.js';
import { DatePicker, type FieldProps, type ICommonField, useFieldApi } from '@oms/frontend-foundation';
import { formatDateToFixAtdlTimeString, getLocalDateTime } from '../utils.ts/fixatdl-date.util.js';
import { AtdlFormats } from '@valstro/fixatdl-core';

const format = 'HH:mm:ss';

export const TimePickerField: FC<FieldProps<TimePickerFieldProps>> = React.memo(
  ({ initialValue, fieldDef: _fieldDef, ...props }) => {
    const {
      input: { onChange, value: _value }
    } = useFieldApi<ICommonField<string>>(props);

    let value = _value || initialValue;
    if (!AtdlFormats.Dates.isTime(value)) {
      value = null;
    }

    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
      if (value && !initialized) {
        onChangeRef.current(value);
        setInitialized(true);
      }
    }, [value, initialized]);

    const handleChange = useCallback((value: Date | null) => {
      onChangeRef.current(value ? formatDateToFixAtdlTimeString(value) : value);
    }, []);

    const localDateTime = useMemo(() => (value ? getLocalDateTime(value)?.toString() : undefined), [value]);

    return (
      <div style={{ minWidth: '180px', maxWidth: '180px', marginRight: '10px' }}>
        <DatePicker
          {...props}
          dateFormat={format}
          placeholder={format}
          timePicker="selectOnly"
          showTimeSelectOnly
          initialValue={localDateTime}
          value={localDateTime}
          valueChange={handleChange}
          component="date-picker"
        />
      </div>
    );
  },
  isEqual
);
