import { useMemo } from 'react';
import { OrderType, type VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import {
  APP_DISPLAY_FIELD_COMPONENT_TYPE,
  AppDisplayGrid,
  type AppDisplayGridProps
} from '@oms/frontend-foundation';
import {
  type UpdatedTradingOrder,
  withUpdatedTradingOrder
} from '@app/data-access/hoc/with-updated-trading-order';
import { Box, Text } from '@oms/shared-frontend/ui-design-system';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { paneStyle as sx } from './trading-order-view.style';
import { t } from '@oms/codegen/translations';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';
import { TradingOrderViewUtilityButtonGroup } from './trading-order-view.utility-button-group';

const getDisplayGridItems = (
  tradingOrder: VisibleTradingOrderInfoWithAllocationsFragment
): AppDisplayGridProps['items'] => {
  const isMarketOrder = tradingOrder.orderType === OrderType.Market;
  return [
    {
      label: 'Side',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: tradingOrder.sideType || []
      }
    },
    {
      label: 'Instrument',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: tradingOrder.instrument?.mappings.displayCode || ''
      }
    },
    {
      label: 'Account',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: tradingOrder.tradingAccount?.name || ''
      }
    },
    {
      label: 'Quantity',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: tradingOrder.quantity || '',
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: isMarketOrder
        ? {
            type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
            value: 'Market'
          }
        : {
            type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
            value: tradingOrder.limitPrice || '',
            format: 'price'
          }
    },
    {
      label: 'Order Status',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: mapTradingOrderStatus(tradingOrder.status)
      }
    },
    {
      label: 'Category',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: mapTradingOrderCategory(tradingOrder.category)
      }
    },
    {
      label: t('app.orders.orderMonitor.routeTo'),
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: tradingOrder.destinationName || ''
      }
    },
    {
      label: 'Strategy',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: tradingOrder.strategy?.uirep || tradingOrder.strategy?.name || ''
      }
    },
    {
      label: 'Locate',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: tradingOrder.locate || ''
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  columnGap: 10,
  rowGap: 3
};

export const TradingOrderViewOrderInformationWidget = ({ data: order }: UpdatedTradingOrder) => {
  const displayGridItems = useMemo(() => {
    if (!order) {
      return [];
    }

    return getDisplayGridItems(order);
  }, [order]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Order Information</Text>
        <TradingOrderViewUtilityButtonGroup
          orderStatus={order.status}
          orderId={order.id}
          orderSymbol={order.instrument?.mappings.displayCode}
        />
      </Box>
      <AppDisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedTradingOrder(TradingOrderViewOrderInformationWidget, { sx });
