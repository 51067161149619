import React from 'react';
import { type IAdvancedSelectField } from './advanced-select.types';
import type { FieldProps } from '../../types';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { useAdvancedSelectQuery } from './advanced-select-query.hook';
import { Autocomplete } from '../../../popovers/autocomplete/popover.autocomplete';
import { useFieldApi } from '../../helpers';

export const AdvancedSelectField: React.FC<FieldProps<IAdvancedSelectField<any>>> = (props) => {
  const field = useFieldApi<IAdvancedSelectField<any>>(props);
  const { fieldWrapperProps, autoCompleteProps } = useAdvancedSelectQuery(field);

  return (
    <FieldWrapper {...fieldWrapperProps} meta={field.meta}>
      <Autocomplete
        {...autoCompleteProps}
        onChange={props.onChange || autoCompleteProps.onChange}
        value={props.value || autoCompleteProps.value}
      />
    </FieldWrapper>
  );
};
