import { OfflineDatabaseSignal } from '@app/data-access/memory/offline-database.signal';
import {
  sanitizeDocDataToContext,
  type WindowContextDocType
} from '@app/data-access/offline/collections/window-contexts.collection';
import type { OfflineDatabase } from '@app/data-access/offline/offline-database';
import {
  COMMON_ACTOR_TYPE,
  type CreateActorOptions,
  Plugin,
  type AnyRecord,
  type Workspace
} from '@valstro/workspace';
import { debounce } from 'lodash';
import type { Subscription } from 'rxjs';
import type { DependencyContainer } from 'tsyringe';
import type { AppWindowActorSchema, AppWindowContext } from '@app/app-config/workspace.config';
interface RememberWindowPluginOptions {
  includeWidgetTypes?: string[];
  excludeWindowWidgetCategories?: string[];
  container: DependencyContainer;
}

/**
 * Remember Window plugin
 * - Saves window position & size of windows after they are moved or resized
 * - Restores window position & size on spawnChild
 */
export const rememberWindowPlugin = (options: RememberWindowPluginOptions) =>
  Plugin.create({
    name: 'valstro-remember-window-plugin',
    pluginFn: ({ workspace }) => {
      const signalService = options.container.resolve(OfflineDatabaseSignal);

      let offlineDb: OfflineDatabase | undefined;
      // Create a store to remember & subscribe to window context changes from databse
      // Note: We want to keep this in memory as it's a small amount of data & we want context lookups to be fast
      const store = new WindowRememberStore();

      // Populate store with window contexts from database
      let windowCollectionChangesSub: Subscription | undefined;
      const offlineReadySub = signalService.ready$.subscribe(({ db }) => {
        offlineDb = db.db;
        windowCollectionChangesSub = db.collections.window_contexts.find().$.subscribe((docs) => {
          const data = docs.map((doc) => doc.toMutableJSON());
          store.populate(data); // Populate store with window contexts
        });
      });

      const debouncedWindowRemember = debounce(
        (offlineDb: OfflineDatabase, widgetType: string, ctx: AppWindowContext) => {
          const scaleFactor = ctx.scaleFactor || 1;

          if (
            ctx.x === undefined ||
            ctx.y === undefined ||
            ctx.width === undefined ||
            ctx.height === undefined
          ) {
            return;
          }

          offlineDb.collections.window_contexts
            .upsert({
              type: widgetType,
              width: ctx.width / scaleFactor,
              height: ctx.height / scaleFactor,
              isMaximized: ctx.isMaximized,
              isFullscreen: ctx.isFullscreen,
              x: ctx.x / scaleFactor,
              y: ctx.y / scaleFactor,
              isPinned: ctx.isPinned
            })
            .catch(console.error);
        },
        750
      );

      // Add hook to save window context against widget type on context change
      workspace
        .addActorHook<AppWindowActorSchema>(COMMON_ACTOR_TYPE.WINDOW)
        .after('contextChange', ([ctx, op], api) => {
          if (!offlineDb) {
            return;
          }

          const { isUserInteraction = false } = op || {};

          if (!isUserInteraction) {
            return;
          }

          const actor = api.getDefinition();
          const widgetType = WindowRememberStore.shouldRemember(workspace, actor, options);

          if (!widgetType) {
            return;
          }

          if (
            ctx.x === undefined ||
            ctx.y === undefined ||
            ctx.width === undefined ||
            ctx.height === undefined
          ) {
            return;
          }

          debouncedWindowRemember(offlineDb, widgetType, ctx);

          return [ctx, op];
        });

      function onBeforeSpawnChild([createOptions, schema]: [
        CreateActorOptions<AnyRecord, string>,
        any // Note, actualy type is not exported, we don't need in this fn so we can ignore
      ]): [CreateActorOptions<AnyRecord, string>, any] | void {
        const widgetType = WindowRememberStore.shouldRemember(workspace, createOptions, options);
        if (!widgetType) {
          return;
        }

        const rememberedWindow = store.get(widgetType);
        if (!rememberedWindow) {
          return;
        }

        return [
          {
            ...createOptions,
            context: {
              ...(createOptions.context || {}),
              ...sanitizeDocDataToContext(rememberedWindow)
            }
          },
          schema
        ];
      }

      // Apply persisted window context on spawnChild for windows & widgets (if available)
      workspace.addActorHook(COMMON_ACTOR_TYPE.WINDOW).before('spawnChild', onBeforeSpawnChild);
      workspace.addActorHook(COMMON_ACTOR_TYPE.WIDGET).before('spawnChild', onBeforeSpawnChild);

      return function unsubscribe() {
        windowCollectionChangesSub?.unsubscribe();
        offlineReadySub.unsubscribe();
      };
    }
  });

/**
 * Window Remember Store
 * - Stores windows context in map
 * - Retrieves windows context from map
 */
class WindowRememberStore {
  /**
   * Map to store window contexts from offline db in memory
   */
  private windows: Map<string, WindowContextDocType> = new Map();

  /**
   * Helper function to determine if a window should be remembered
   *
   * @param createOptions - Actor create options
   * @param options - Plugin options
   * @returns string | null
   */

  public static shouldRemember(
    workspace: Workspace,
    createOptions: CreateActorOptions<AppWindowContext>,
    options: RememberWindowPluginOptions
  ): string | null {
    if (
      createOptions.type !== COMMON_ACTOR_TYPE.WINDOW ||
      createOptions.id === workspace.getLeaderProcessId() ||
      createOptions.applyingSnapshotFromId !== undefined
    ) {
      return null;
    }

    const { includeWidgetTypes = [], excludeWindowWidgetCategories = [] } = options;

    const { context } = createOptions;
    const { meta } = context || {};
    const widgetType = String(meta?.widgetType);
    const widgetCategory = String(meta?.widgetCategory);

    if (
      widgetType &&
      widgetCategory &&
      (includeWidgetTypes.length === 0 || includeWidgetTypes.includes(widgetType)) &&
      !excludeWindowWidgetCategories.includes(widgetCategory)
    ) {
      return widgetType;
    }

    return null;
  }

  /**
   * Populates the store with window contexts to restore
   *
   * @param windowContexts - Window contexts to restore
   */
  public populate(windowContexts: WindowContextDocType[]) {
    this.windows.clear();
    windowContexts.forEach((windowContext) => {
      this.windows.set(windowContext.type, windowContext);
    });
  }

  /**
   * Get window context by widget type & category
   *
   * @param widgetCategory - string
   * @param widgetKey - string
   * @returns WindowContextDocType | null
   */
  public get(widgetKey: string): WindowContextDocType | null {
    return this.windows.get(widgetKey) || null;
  }
}
