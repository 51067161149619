import type { ColDef } from '@ag-grid-community/core';
import type { EnrichedColumnDef, TableServerColumnLibrary, TableServerGridType } from './table-server.types';
import {
  applyFormatting,
  getExtendedColDef,
  getFilterComponent,
  getFloatingFilterComponent,
  getHeader,
  getValueFormatter
} from './table-server.utils';
import { createAgGridHeaderTemplate, type GridBuilderColumnLibrary } from '@oms/frontend-vgrid';
import { omit } from 'lodash';
import type { AnyRecord } from '@oms/frontend-foundation';

const getExtendedColumnDef = (columnDef: EnrichedColumnDef): EnrichedColumnDef =>
  columnDef.extends ? { ...getExtendedColDef(columnDef.extends), ...columnDef } : columnDef;

const getValueFormatterIfExists = (colDef: EnrichedColumnDef) =>
  colDef.valueFormatter ? getValueFormatter(colDef.valueFormatter) : undefined;

const getFilterValueFormatterIfExists = (colDef: EnrichedColumnDef) =>
  colDef.filterParams?.valueFormatter ? getValueFormatter(colDef.filterParams.valueFormatter) : undefined;

const getFloatingFilterComponentIfExists = (colDef: EnrichedColumnDef) =>
  colDef.floatingFilterComponent
    ? getFloatingFilterComponent(colDef.floatingFilterComponent)
    : colDef.floatingFilterComponent;

const getFilterComponentIfExists = (colDef: EnrichedColumnDef) =>
  colDef.filter ? getFilterComponent(colDef.filter) : undefined;

const getHeaderComponentParams = (
  longName: string | undefined,
  shortName: string | undefined,
  colDef: EnrichedColumnDef,
  isDefaultColumn?: boolean
) =>
  !isDefaultColumn && (longName || shortName)
    ? {
        ...(colDef.headerComponentParams as AnyRecord),
        template: createAgGridHeaderTemplate(longName || shortName || '', shortName)
      }
    : undefined;

const getHeaderName = (longName: string | undefined, isDefaultColumn?: boolean) =>
  !isDefaultColumn && longName ? longName : undefined;

const createColumnDef = (
  columnDef: EnrichedColumnDef,
  field: string,
  gridType: TableServerGridType,
  isDefaultColumn?: boolean
): ColDef => {
  const extendedColDef = getExtendedColumnDef(columnDef);
  const valueFormatter = getValueFormatterIfExists(extendedColDef);
  const filterValueFormatter = getFilterValueFormatterIfExists(extendedColDef);
  const floatingFilterComponent = getFloatingFilterComponentIfExists(extendedColDef);
  const filterComponent = getFilterComponentIfExists(extendedColDef) || extendedColDef.filter;
  const { shortName, longName } = getHeader(field, gridType);
  const headerComponentParams = getHeaderComponentParams(
    longName,
    shortName,
    extendedColDef,
    isDefaultColumn
  );
  const headerName = getHeaderName(longName, isDefaultColumn);

  return {
    ...omit(extendedColDef, [
      'extends',
      'format',
      'filter',
      'floatingFilterComponent',
      'valueFormatter',
      'filterParams'
    ]),
    ...(field && { field }),
    ...(valueFormatter && { valueFormatter }),
    ...(extendedColDef.format && applyFormatting(extendedColDef.format)),
    ...(extendedColDef.filterParams && {
      filterParams: {
        ...extendedColDef.filterParams,
        valueFormatter: filterValueFormatter
      }
    }),
    ...(floatingFilterComponent && { floatingFilterComponent }),
    ...(filterComponent && { filter: filterComponent }),
    ...(headerComponentParams && { headerComponentParams }),
    ...(headerName && { headerName })
  };
};

export const buildColumnDefs = (
  colLibrary: TableServerColumnLibrary,
  gridType: TableServerGridType
): GridBuilderColumnLibrary => {
  const defaultColumn =
    colLibrary.defaultColumn && createColumnDef(colLibrary.defaultColumn, '', gridType, true);

  const columns = Object.entries(colLibrary.columns).map(([field, columnDef]) =>
    createColumnDef(columnDef, field, gridType)
  );

  return { columns, defaultColumn };
};
