import type { FetchPolicy } from '@apollo/client';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import { type DataSourceCommon, type ICrudService, toGqlDatasource } from '@oms/frontend-foundation';
import {
  GetTradeRepairRequestByIdDocument,
  type GetTradeRepairRequestByIdQuery,
  type GetTradeRepairRequestByIdQueryVariables,
  GetTradeRepairRequestsDocument,
  type GetTradeRepairRequestsQuery,
  type GetTradeRepairRequestsQueryVariables,
  RejectFailedTradesDocument,
  RejectFailedTradesMutation,
  RejectFailedTradesMutationVariables,
  RepairFailedTradeInput,
  RepairFailedTradesDocument,
  RepairFailedTradesMutation,
  RepairFailedTradesMutationVariables,
  RetryFailedTradesDocument,
  RetryFailedTradesMutation,
  RetryFailedTradesMutationVariables,
  type TradeRepairRequestFragment
} from '@oms/generated/frontend';
import { cleanMaybe, Logger } from '@oms/shared/util';
import { type Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';

@singleton()
export class TradeRepairRequestService implements ICrudService<TradeRepairRequestFragment> {
  protected name: string = 'TradeRepairRequestService';
  protected logger: Logger;

  protected fetchPolicy: FetchPolicy = 'cache-first';

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
  }

  // 🔍 Queries --------------------------------------------------------- /

  public watchAll$ = (): Observable<DataSourceCommon<TradeRepairRequestFragment>> => {
    const result = this.apolloClient.watchQuery<
      GetTradeRepairRequestsQuery,
      GetTradeRepairRequestsQueryVariables
    >({
      query: GetTradeRepairRequestsDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 5000
    });

    return result.pipe(
      toGqlDatasource(
        ({ getTradeRepairRequests }) =>
          cleanMaybe(getTradeRepairRequests?.results, []) as TradeRepairRequestFragment[]
      )
    );
  };

  public getTradeRepairRequestById(repairId: string) {
    const query = this.gqlResponse.wrapQuery<
      GetTradeRepairRequestByIdQuery,
      GetTradeRepairRequestByIdQueryVariables
    >({
      query: GetTradeRepairRequestByIdDocument,
      variables: {
        tradeRepairRequestId: repairId
      }
    });

    return query.exec();
  }

  // 💱 Mutations --------------------------------------------------------- /

  public repairTradesRequest(repairTradeInput: RepairFailedTradeInput[], dryRun: boolean = false) {
    const mutation = this.gqlResponse.wrapMutate<
      RepairFailedTradesMutation,
      RepairFailedTradesMutationVariables
    >({
      mutation: RepairFailedTradesDocument,
      variables: {
        input: {
          input: repairTradeInput,
          dryRun
        }
      },
      refetchQueries: [GetTradeRepairRequestsDocument]
    });

    return mutation.exec();
  }

  public rejectWithReason(ids: string[], rejectText: string) {
    const mutation = this.gqlResponse.wrapMutate<
      RejectFailedTradesMutation,
      RejectFailedTradesMutationVariables
    >({
      mutation: RejectFailedTradesDocument,
      variables: {
        input: {
          data: ids.map((id) => ({
            tradeRepairRequestId: id,
            rejectText: rejectText
          }))
        }
      },
      refetchQueries: [GetTradeRepairRequestsDocument]
    });

    return mutation.exec();
  }

  public retryCreateTradeRequests(ids: string[]) {
    const mutation = this.gqlResponse.wrapMutate<
      RetryFailedTradesMutation,
      RetryFailedTradesMutationVariables
    >({
      mutation: RetryFailedTradesDocument,
      variables: {
        input: {
          tradeRepairRequestIds: ids
        }
      },
      refetchQueries: [GetTradeRepairRequestsDocument]
    });

    return mutation.exec();
  }
}

export default TradeRepairRequestService;
