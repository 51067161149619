import { useMemo } from 'react';
import { getAppEnv } from './env.util';
import { AppEnv, type AppEnvFormatOptions } from './app-env.class';

export const useAppEnv = (fallback: AppEnv = AppEnv.Dev): AppEnv => {
  return useMemo(() => getAppEnv(fallback), [fallback]);
};

export const useFormattedAppEnv = (options?: AppEnvFormatOptions & { fallback?: AppEnv }): string => {
  const appEnv = useAppEnv(options?.fallback);
  return useMemo(() => appEnv.format({ type: options?.type, case: options?.case, suppressTag: options?.suppressTag }), [appEnv, options?.type, options?.case, options?.suppressTag]);
};
