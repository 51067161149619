import { Box, Spinner, type Sprinkles } from '@oms/shared-frontend/ui-design-system';
import {
  type ComponentType,
  type Dispatch,
  useEffect,
  useState,
  type SetStateAction,
  useCallback
} from 'react';
import { type UnknownRecord, useService } from '@oms/frontend-foundation';
import {
  useClosestAppFlexLayoutActorProps,
  useCurrentAppWidgetActorProps
} from '@app/common/workspace/workspace.hooks';

interface TrackingService<T> {
  track(
    id: string,
    setData: Dispatch<SetStateAction<T | undefined>>,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ): () => void;
}

export type UpdatedData<T> = { data: T };
export type UpdatedDataProps = { idProp?: string; sx?: Sprinkles };

export const withUpdatedData =
  <T extends UnknownRecord>(
    Component: ComponentType<UpdatedData<T>>,
    trackingService: new (...args: any[]) => TrackingService<T>,
    props: UpdatedDataProps
  ) =>
  () => {
    const [flexProps] = useClosestAppFlexLayoutActorProps();
    const [widgetProps] = useCurrentAppWidgetActorProps();
    const { sx, idProp } = props;

    const flexId: unknown = flexProps?.[idProp ?? 'id'];
    const widgetId: unknown = widgetProps?.[idProp ?? 'id'];
    const id = flexId || widgetId;
    if (!id) {
      throw new Error('Can not determine id');
    }
    if (typeof id !== 'string') {
      throw new Error('id should be a string');
    }

    const [dataId] = useState(id);
    const [error, setError] = useState<Error>();
    const [data, setData] = useState<T>();
    const service = useService(trackingService);

    useEffect(() => {
      if (!dataId) {
        return;
      }
      return service.track(dataId, setData, setError);
    }, [dataId, service]);

    if (!data && !error) {
      return <Spinner fillArea sx={sx} />;
    }
    if (error) {
      return <Box sx={sx}>Error: {error.message}</Box>;
    }
    if (!data) {
      return <Box sx={sx}>Error: Data not found</Box>;
    }
    return <Component data={data} />;
  };

export const useUpdatedData = <T extends UnknownRecord>(
  trackingService: new (...args: any[]) => TrackingService<T>,
  id: string | null | undefined
) => {
  const [dataId, setDataId] = useState(id);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<T>();
  const service = useService(trackingService);
  const isLoading = dataId && !data && !error;

  const changeDataId = useCallback(
    (newDataId: string | null | undefined) => {
      setData(undefined); // Force isLoading to be true until data finished fetching
      setDataId(newDataId);
    },
    [dataId]
  );

  useEffect(() => {
    if (!dataId) {
      return;
    }
    return service.track(dataId, setData, setError);
  }, [dataId, service]);

  return [data, isLoading, error, changeDataId] as const;
};
