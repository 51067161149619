import { comboBoxItemsFrom } from '@oms/frontend-foundation';
import { FieldContract } from '@oms/frontend-foundation';
import {
  commonTimeInForceOptions,
  makeTifDurationOption,
  makeTifGtdTimestampOption
} from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { createVenueField } from '@app/forms/common/fields/venue-field/venue.field.common';
import type { MontagePreferencesFormOutput } from './montage-preferences.types';
import { OrderSideType, PriceType, QuantityType, type TimeInForce } from '@oms/generated/frontend';
import { getOrderSideTypeLabel, getPriceTypeLabel, getQuantityLabel } from './montage-preferences.util';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

const msfc = FieldContract.create<MontagePreferencesFormOutput, AdditionalFormFieldUnion>();

type BidAskField<K extends keyof MontagePreferencesFormOutput> = keyof Pick<MontagePreferencesFormOutput, K>;

const makeInitiateOrder = (fieldName: BidAskField<'askInitiateOrder' | 'bidInitiateOrder'>) =>
  msfc.field(fieldName, 'checkbox').options({
    label: 'Initiate order'
  });

const makeSideType = (fieldName: BidAskField<'askSideType' | 'bidSideType'>) =>
  msfc.field(fieldName, 'select').options({
    label: 'Side',
    options: comboBoxItemsFrom.enum(OrderSideType, {
      label: getOrderSideTypeLabel
    })
  });

const makeOrderSize = (fieldName: BidAskField<'askOrderSize' | 'bidOrderSize'>) =>
  msfc.field(fieldName, 'advanced-select').options({
    label: 'Order size',
    isDisabled: true,
    validate: [{ type: 'required' }]
  });

const makeQuantity = (fieldName: BidAskField<'askQuantityType' | 'bidQuantityType'>) =>
  msfc.field(fieldName, 'select').options({
    label: 'Quantity',
    options: comboBoxItemsFrom.enum(QuantityType, {
      label: getQuantityLabel
    })
  });

export const makeQuantityValue = (fieldName: BidAskField<'askQuantityValue' | 'bidQuantityValue'>) =>
  msfc.field(fieldName, 'number-format').options({
    label: 'Quantity value',
    format: 'number',
    validate: [{ type: 'required' }]
  });

const makePrice = (fieldName: BidAskField<'askPriceType' | 'bidPriceType'>) =>
  msfc.field(fieldName, 'select').options({
    label: 'Price',
    options: comboBoxItemsFrom
      .enum(PriceType, {
        label: getPriceTypeLabel
      })
      .filter(({ value }) => value !== PriceType.Blank)
  });

const makeDisplaySize = (fieldName: BidAskField<'askDisplaySize' | 'bidDisplaySize'>) =>
  msfc.field(fieldName, 'number-format').options({
    label: 'Display size',
    format: 'number'
  });

const makeDestinationId = (fieldName: BidAskField<'askDestinationId' | 'bidDestinationId'>) =>
  createVenueField(msfc, fieldName);

export const makeStrategy = (fieldName: BidAskField<'askStrategy' | 'bidStrategy'>) => (venueId: string) =>
  msfc.field(fieldName, 'fixatdl-strategy').options({
    label: 'Strategy',
    venueIdKey: venueId,
    strategyFieldKey: fieldName,
    isDialogEnabled: false
  });

// TODO: used text-field until fixatdl-strategy is migrated
const makeStrategyParameters = (fieldName: BidAskField<'askStrategyParameters' | 'bidStrategyParameters'>) =>
  msfc.field(fieldName, 'text-field').options({
    label: 'Strategy presets'
  });

export const hideOddLots = msfc.field('hideOddLots', 'checkbox').options({
  label: 'Hide odd lots'
});

export const displayQuotesInShares = msfc.field('displayQuotesInShares', 'checkbox').options({
  label: 'Display quotes in shares'
});

export const sendAttributable = msfc.field('sendAttributable', 'checkbox').options({
  label: 'Send Attributable'
});

export const bidInitiateOrder = makeInitiateOrder('bidInitiateOrder');
export const askInitiateOrder = makeInitiateOrder('askInitiateOrder');

export const bidSideType = makeSideType('bidSideType');
export const askSideType = makeSideType('askSideType');

export const bidQuantityType = makeQuantity('bidQuantityType');
export const askQuantityType = makeQuantity('askQuantityType');

export const bidTimeInForceTypeOptions = [makeTifDurationOption('bidTifDuration')]
  .concat(commonTimeInForceOptions)
  .concat(makeTifGtdTimestampOption('bidGtdTimestamp'));

export const askTimeInForceTypeOptions = [makeTifDurationOption('askTifDuration')]
  .concat(commonTimeInForceOptions)
  .concat(makeTifGtdTimestampOption('askGtdTimestamp'));

export const bidTimeInForce = msfc
  .field('bidTimeInForceType', 'multi-input')
  .multiInputOptions<TimeInForce, string>({
    name: 'tif',
    label: 'TIF',
    validate: [{ type: 'validateTifInput' }],
    options: bidTimeInForceTypeOptions,
    style: {
      minWidth: '180px'
    }
  });

export const askTimeInForce = msfc
  .field('askTimeInForceType', 'multi-input')
  .multiInputOptions<TimeInForce, string>({
    name: 'tif',
    label: 'TIF',
    validate: [{ type: 'validateTifInput' }],
    options: askTimeInForceTypeOptions,
    style: {
      minWidth: '180px'
    }
  });

export const bidQuantityValue = makeQuantityValue('bidQuantityValue');
export const askQuantityValue = makeQuantityValue('askQuantityValue');

export const bidOrderSize = makeOrderSize('bidOrderSize');
export const askOrderSize = makeOrderSize('askOrderSize');

export const bidPriceType = makePrice('bidPriceType');
export const askPriceType = makePrice('askPriceType');

export const bidDisplaySize = makeDisplaySize('bidDisplaySize');
export const askDisplaySize = makeDisplaySize('askDisplaySize');

export const bidDestinationId = makeDestinationId('bidDestinationId');
export const askDestinationId = makeDestinationId('askDestinationId');

export const bidStrategy = makeStrategy('bidStrategy')(bidDestinationId.name);
export const askStrategy = makeStrategy('askStrategy')(askDestinationId.name);

export const bidStrategyParameters = makeStrategyParameters('bidStrategyParameters');
export const askStrategyParameters = makeStrategyParameters('askStrategyParameters');
