import type { EnrichedColumnDef } from '@app/common/grids/table-server/table-server.types';
import { CellBadgeClassEnum } from '@oms/frontend-vgrid';
import {
  OrderSettleType,
  OrderSideType,
  OrderType,
  TimeInForce,
  TradingOrderCapacity
} from '@oms/generated/frontend';

// ************** Generic *************/

export const defaultCol: EnrichedColumnDef = {
  resizable: true,
  sortable: true,
  filter: true
};

export const text: EnrichedColumnDef = {
  resizable: true,
  sortable: true,
  width: 110,
  filter: 'agTextColumnFilter'
};

export const currency: EnrichedColumnDef = {
  filter: 'agTextColumnFilter',
  width: 110
};

export const dateTime: EnrichedColumnDef = {
  filter: 'agDateColumnFilter',
  format: 'datetime',
  width: 180
};

export const date: EnrichedColumnDef = {
  filter: 'agDateColumnFilter',
  format: 'date',
  width: 110
};

export const timestamp: EnrichedColumnDef = {
  filter: 'agDateColumnFilter',
  format: 'time12',
  width: 200,
  minWidth: 90
};

// ************** Orders *************/

export const id: EnrichedColumnDef = {
  filter: 'agTextColumnFilter',
  hide: true
};

export const instrumentDisplayCode: EnrichedColumnDef = {
  width: 110,
  minWidth: 100,
  filter: 'agTextColumnFilter'
};

export const instrumentLongName: EnrichedColumnDef = {
  minWidth: 100,
  hide: true
};

export const instrumentCusip: EnrichedColumnDef = {
  width: 110,
  minWidth: 90,
  hide: true
};

export const instrumentId: EnrichedColumnDef = {
  width: 110,
  minWidth: 90,
  hide: true
};

export const instrumentExchCode: EnrichedColumnDef = {
  width: 200,
  maxWidth: 200,
  hide: true
};

export const numeric: EnrichedColumnDef = {
  type: 'numericColumn',
  filter: 'agNumberColumnFilter',
  minWidth: 100,
  width: 110,
  maxWidth: 160,
  resizable: true,
  sortable: true,
  format: 'number'
};

export const price: EnrichedColumnDef = {
  ...numeric,
  format: 'price'
};

export const side: EnrichedColumnDef = {
  width: 82,
  maxWidth: 500,
  minWidth: 75,
  cellRenderer: 'SideTypeCellRenderer',
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [
      OrderSideType.Buy,
      OrderSideType.Sell,
      OrderSideType.Short,
      OrderSideType.Exempt,
      OrderSideType.Btc
    ]
  }
};

export const quantity: EnrichedColumnDef = {
  format: 'quantity',
  filter: 'agNumberColumnFilter',
  width: 95,
  maxWidth: 180
};

export const settleType: EnrichedColumnDef = {
  width: 110,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(OrderSettleType),
    valueFormatter: 'orderSettleType'
  },
  valueFormatter: 'orderSettleType'
};

export const investorAccount: EnrichedColumnDef = {
  ...text,
  filter: false,
  sortable: false,
  minWidth: 90,
  width: 210
};

export const timeInForce: EnrichedColumnDef = {
  width: 80,
  maxWidth: 120,
  minWidth: 75,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(TimeInForce),
    valueFormatter: 'timeInForce'
  },
  valueFormatter: 'timeInForce',
  cellClass: CellBadgeClassEnum.Capital
};

export const user: EnrichedColumnDef = {
  ...text,
  width: 180,
  floatingFilter: true
};

export const orderStatus: EnrichedColumnDef = {
  width: 110,
  maxWidth: 220,
  minWidth: 100
};

export const ownerId: EnrichedColumnDef = {
  ...id,
  width: 110,
  floatingFilter: false,
  filter: 'agTextColumnFilter',
  filterParams: { filterOptions: ['equals'] }
};

export const orderType: EnrichedColumnDef = {
  width: 110,
  maxWidth: 220,
  minWidth: 120,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: Object.values(OrderType),
    valueFormatter: 'orderType'
  },
  valueFormatter: 'orderType',
  cellClass: CellBadgeClassEnum.Capital
};

// ************** Trading orders *************/

export const tradingOrderCapacity: EnrichedColumnDef = {
  ...text,
  width: 95,
  maxWidth: 180,
  filter: 'agSetColumnFilter',
  filterParams: {
    values: [TradingOrderCapacity.Agency, TradingOrderCapacity.Principal]
  }
};
