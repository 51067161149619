import {
  type ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  type SplitButtonActionComponentConfig,
  type SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import { TradingOrderStatus } from '@oms/generated/frontend';
import { cancelTradingOrderOnChange } from './cancel.trading-order.action';
import { modifyTradingOrderOnChange } from './modify.trading-order.action';
import { viewTradingOrderOnChange } from './view.trading-order.action';
import type { TradingOrderRow } from '../trading-order-monitor.contracts';

export const inlineTradingOrderActions: ActionDefFactory<TradingOrderRow> = (builder) =>
  builder
    .name('inline_trading_order_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(130)
        .filter(false)
        .cell((c) =>
          c.dependsOn(['id', 'status']).renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' })
        )
    )
    .onChange<SplitButtonActionComponentConfig<TradingOrderRow>>((ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            case 'inline_view_trading_order':
              return viewTradingOrderOnChange(ctx);
            case 'inline_modify_trading_order':
              return modifyTradingOrderOnChange(ctx);
            case 'inline_cancel_trading_order':
              return cancelTradingOrderOnChange(ctx);
            default:
              throw new Error(`onChange not defined for ${selectedId || ''}`);
          }
        }
      }
    });

function rowActions(rowData: TradingOrderRow): SplitButtonActionConfig[] {
  switch (rowData.status) {
    case TradingOrderStatus.Active:
      return [
        {
          id: 'inline_cancel_trading_order',
          title: 'Cancel'
        },
        {
          id: 'inline_modify_trading_order',
          title: 'Modify'
        }
      ];

    default:
      return rowData.id
        ? [
            {
              id: 'inline_view_trading_order',
              title: 'View Order'
            }
          ]
        : [];
  }
}
