import { ControlTypes, type AllControls, type StrategyQuery, type Timezones } from '@valstro/fixatdl-core';
import { type AtdlFieldPartial, type ControlTransformer, getInitValue } from './control.transformer.types';
import { checkboxListControl } from './checkbox-list.control.transformer';
import { checkboxControl } from './checkbox.control.transformer';
import { clockControl } from './clock.control.transformer';
import { doubleSpinnerControl } from './double-spinner.control.transformer';
import { dropDownListControl } from './drop-down-list.control.transformer';
import { editableDropdownListControl } from './editable-drop-down-list.control.transformer';
import { hiddenFieldControl } from './hidden.control.transformer';
import { labelControl } from './label.control.transformer';
import { multiSelectListControl } from './multi-select.control.transformer';
import { radioButtonListControl } from './radio-button-list.control.transformer';
import { radioButtonControl } from './radio-button.control.transformer';
import { singleSelectListControl } from './single-select.control.transformer';
import { singleSpinnerControl } from './single-spinner.control.transformer';
import { sliderControl } from './slider.control.transformer';
import { textControl } from './text.control.transformer';

const controlMap = new Map<ControlTypes, ControlTransformer<any>>([
  [ControlTypes.Checkbox, checkboxControl],
  [ControlTypes.CheckboxList, checkboxListControl],
  [ControlTypes.DoubleSpinner, doubleSpinnerControl],
  [ControlTypes.DropDownList, dropDownListControl],
  [ControlTypes.EditableDropDownList, editableDropdownListControl],
  [ControlTypes.HiddenField, hiddenFieldControl],
  [ControlTypes.Label, labelControl],
  [ControlTypes.MultiSelectList, multiSelectListControl],
  [ControlTypes.RadioButton, radioButtonControl],
  [ControlTypes.RadioButtonList, radioButtonListControl],
  [ControlTypes.SingleSelectList, singleSelectListControl],
  [ControlTypes.SingleSpinner, singleSpinnerControl],
  [ControlTypes.Slider, sliderControl],
  [ControlTypes.TextField, textControl],
  [ControlTypes.Clock, clockControl]
]);

export const transformControl = (
  control: AllControls,
  query: StrategyQuery,
  formValues: Record<string, any>
): AtdlFieldPartial | undefined => {
  const transformer = controlMap.get(control.type);
  !transformer && console.warn(`Missing transformer for ${control.type}.`);
  const ctrlQuery = query.findControl(control.id);

  if (!ctrlQuery) {
    return;
  }

  formValues[control.id] = getInitValue(ctrlQuery, formValues);
  const result = transformer ? transformer(control, query, formValues) : undefined;

  if (!result) {
    return;
  }

  result.actions = result.actions || {};
  result.actions['resolveProps'] = [
    'stateRulesAction',
    {
      strat: query.strategy,
      fieldConfig: {
        enabled: { prop: 'isDisabled', invert: true, defaultValue: false },
        visible: { prop: 'isVisible', defaultValue: true },
        value: { prop: 'value' }
      }
    }
  ];

  result.validate = result.validate || [];

  if (!Array.isArray(result.validate)) {
    throw new Error('result.validate must be an array');
  }

  const strategyQuery = query;
  const controlQuery = strategyQuery.findControl(control.id);
  const required = controlQuery?.required();
  const param = controlQuery?.parameter();
  const minValue = param?.find<unknown>('minValue');
  const maxValue = param?.find<unknown>('maxValue');
  const minLength = param?.find<number>('minLength');
  const maxLength = param?.find<number>('maxLength');
  const localMktTz =
    controlQuery?.parameter()?.find<Timezones>('localMktTz') || controlQuery?.find<Timezones>('localMktTz');
  let minValMessage: string | undefined = undefined;
  let maxValMessage: string | undefined = undefined;

  if (localMktTz) {
    minValMessage = `Mimimum is ${String(minValue)}.`;
    maxValMessage = `Maximum is ${String(maxValue)}.`;
  }

  if (required) {
    result.isRequired = required;
    result.validate.push({ type: 'required', message: 'This field is required.' });
  }

  if (minValue) {
    result.minValue = minValue;
    result.validate.push({ type: 'minValue', comparator: minValue, required, message: minValMessage });
  }

  if (maxValue) {
    result.maxValue = maxValue;
    result.validate.push({ type: 'maxValue', comparator: maxValue, required, message: maxValMessage });
  }

  if (minLength) {
    result.minLength = minLength;
    result.validate.push({ type: 'minLength', comparator: minLength, required });
  }

  if (maxLength) {
    result.maxLength = maxValue;
    result.validate.push({ type: 'maxLength', comparator: maxLength, required });
  }

  return result;
};
