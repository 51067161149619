import type {
  ActorSchema,
  AnyRecord,
  CommonWindowActorSchemaOptions,
  WindowOperations
} from '@valstro/workspace';
import type { AuthClientState, ParsedIdentityToken } from './keycloak.types';
import type { AppWindowContext } from '@app/app-config/workspace.config';

/**
 * Common Auth Window Contracts
 */
export const COMMON_AUTH_WINDOW = {
  ID: 'auth-window', // Keep same ID to prevent multiple windows
  TYPE: 'auth-window'
} as const;

export type AuthWindowContext = AppWindowContext;

export type AuthWindowOperations<TMeta extends AnyRecord = AnyRecord> = WindowOperations<TMeta> & {
  initialize: (forceAuthState?: AuthClientState) => Promise<void>;
  startLogin: () => Promise<void>;
  finishLogin: () => Promise<void>;
  logout: () => Promise<void>;
};

export type CommonAuthWindowActorSchemaOptions = CommonWindowActorSchemaOptions & {
  forceAuthState?: AuthClientState;
};

export type CommonAuthWindowActorSchema = ActorSchema<
  AuthWindowContext,
  AuthWindowOperations<AnyRecord>,
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  React.FC<{}>,
  CommonAuthWindowActorSchemaOptions
>;

const NA = 'N/A';

/**
 * Default parsed Keycloak Identity token (if not set)
 */
export const DEFAULT_KC_TOKEN_PARSED: ParsedIdentityToken = {
  id: NA,
  sub: NA,
  name: NA,
  email: NA,
  realm_access: {
    roles: []
  },
  group: []
};

export const PERSISTED_AUTH_STATE_KEY = 'auth-state';

export const UNAUTHORIZED_TYPE = 'client/unauthorized';

export const DEFAULT_AUTH_STATE: AuthClientState = {
  lastAuthClientEvent: 'idle',
  isReady: false,
  isAuthenticated: false,
  sessionId: null,
  token: null,
  tokenParsed: null,
  refreshToken: null,
  hasError: false,
  error: '',
  roles: [],
  expiry: null
};

export const AUTH_EVENT_ACTION = {
  LOGIN: 'login',
  LOGOUT: 'logout'
} as const;

export type AuthEventAction = typeof AUTH_EVENT_ACTION[keyof typeof AUTH_EVENT_ACTION];
