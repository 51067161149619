import { DisplayGrid, Switch, Flex } from '@oms/shared-frontend/ui-design-system';
import { useCallback, type FC, type ChangeEventHandler, useEffect, useState, useMemo } from 'react';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import {
  useEnhancedFormApi,
  type FieldProps,
  type ICommonField,
  useFieldApi,
  type AppDisplayGridProps
} from '@oms/frontend-foundation';
import { type FORM_COMPONENT_TYPE } from '@app/forms/form-builder/common/form.contracts';
import * as styles from './trade-through-obligation-field.component.css';
import { useLevel2SipQuotes } from '../../../data-access/services/trading/level2/use-level2-connection.hook';
import { RegNmsApplicability } from '@oms/generated/frontend';
import { getTradeThroughDetails, type TradeThroughDetails } from '@oms/shared/regnms';
import type { PrincipalFillFormContract } from '../principal-fill/principal-fill.contracts';
import type { CrossPrincipalFillFieldContract } from '../cross-principal-fill/cross-principal-fill.form-common';

const applicabilityMap: Partial<{ [key in RegNmsApplicability]: string }> = {
  [RegNmsApplicability.Exempt]: 'Exempt',
  [RegNmsApplicability.NotApplicable]: 'N/A',
  [RegNmsApplicability.Error]: 'N/A'
};

const getTradeThroughLabel = (data: TradeThroughDetails, regNmsApplicability: RegNmsApplicability) =>
  regNmsApplicability === RegNmsApplicability.Applicable
    ? data.tradeThrough
      ? 'Yes'
      : data.malformed
      ? 'Error'
      : 'No'
    : applicabilityMap[regNmsApplicability];

const getGridItems = (
  data: TradeThroughDetails,
  regNmsApplicability: RegNmsApplicability
): AppDisplayGridProps => ({
  gridProps: {
    columns: 9
  },
  items: [
    {
      label: 'Trade through',
      component: {
        type: 'Text',
        value: getTradeThroughLabel(data, regNmsApplicability) || '-'
      },
      colSpan: 3
    },
    {
      label: 'Side',
      component: {
        type: 'Text',
        value: regNmsApplicability === RegNmsApplicability.Applicable ? (data?.side || '-') : '-'
      },
      colSpan: 2
    },
    {
      label: 'Qty',
      component: {
        type: 'Numeric',
        value: regNmsApplicability === RegNmsApplicability.Applicable ? (data?.quantity || '-') : '-',
        format: 'quantity'
      },
      colSpan: 2
    },
    {
      label: 'Avg px',
      component: {
        type: 'Numeric',
        value: regNmsApplicability === RegNmsApplicability.Applicable ? (data?.averagePrice || '-') : '-',
        format: 'number'
      },
      colSpan: 2
    }
  ]
});

export type TradeThrough = { allowSweep: boolean };

export interface ITradeThroughObligation<TValidator = Validator>
  extends ICommonField<typeof FORM_COMPONENT_TYPE.TRADE_THROUGH_OBLIGATION, TradeThrough, TValidator> {}

export const TradeThroughObligation: FC<
  FieldProps<{
    name: Extract<keyof (PrincipalFillFormContract | CrossPrincipalFillFieldContract), 'tradeThrough'>;
  }>
> = (props) => {
  const formApi = useEnhancedFormApi<PrincipalFillFormContract | CrossPrincipalFillFieldContract>();
  const { instrument, price, limitPrice, regNmsApplicability } = formApi.getState().values;

  const {
    input: { value }
  } = useFieldApi<{}, TradeThrough>(props);
  const level2SipQuotes = useLevel2SipQuotes(instrument.id, regNmsApplicability);
  const [sipQuotesData, setSipQuotesData] = useState<TradeThroughDetails>({
    tradeThrough: undefined,
    side: undefined,
    quantity: undefined,
    averagePrice: undefined,
    bids: [],
    asks: [],
    malformed: false
  });

  const [allowSweep, setAllowSweep] = useState(value.allowSweep);

  useEffect(() => {
    setAllowSweep(value.allowSweep);
  }, [value.allowSweep]);

  useEffect(() => {
    if (regNmsApplicability === RegNmsApplicability.Applicable) {
      const tradeThroughDetails = getTradeThroughDetails(level2SipQuotes, price || limitPrice);
      setSipQuotesData(tradeThroughDetails);
    }
  }, [level2SipQuotes, regNmsApplicability, price, limitPrice]);

  const handleAllowSeepChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => {
      formApi.change(props.name, { allowSweep: target.checked });
    },
    [formApi, props.name]
  );

  const displayGridItems = useMemo(() => {
    return getGridItems(sipQuotesData, regNmsApplicability);
  }, [sipQuotesData, regNmsApplicability]);

  return (
    <Flex>
      <DisplayGrid {...displayGridItems} labelMargin={6} width="550px" />
      <Switch
        checked={allowSweep}
        onChange={handleAllowSeepChange}
        sx={{ marginLeft: 10 }}
        className={styles.allowSweepSwitch}
      >
        Allow sweep
      </Switch>
    </Flex>
  );
};
