import {
  type ActionComponentConfig,
  type ActionContext,
  type ActionDefFactory,
  INLINE_SPLIT_BUTTON_ACTION,
  type SplitButtonActionComponentConfig,
  type SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import { NotificationResourceTemplates } from '@oms/generated/frontend';
import type { Notification } from '../notifications.contracts';
import { openConfirmation } from '@app/generated/sdk';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { t } from '@oms/codegen/translations';

// TODO: This is a placeholder until each of the notification actions' OnChange handlers are supported.
const placeholderOnChange = async (ctx: ActionContext<Notification, ActionComponentConfig<Notification>>) => {
  const { lifecycle, workspace } = ctx;

  if (lifecycle === 'change') {
    try {
      const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: 'Placeholder',
        componentProps: {
          autoClose: true,
          message: 'Are you sure you want to do X?',
          confirmButtonText: t('app.common.yes'),
          cancelButtonText: t('app.common.no')
        },
        windowOptions: {
          width: 400,
          height: 180
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          // For now do nothing.
          throw new Error('Function not implemented.');
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const inlineNotificationsActions: ActionDefFactory<Notification> = (builder) =>
  builder
    .name('inline_notifications_split_button')
    .inline((col) =>
      col
        .header('Actions')
        .initialWidth(140)
        .filter(false)
        .cell((c) => c.renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' }))
    )
    .onChange<SplitButtonActionComponentConfig<Notification>>(async (ctx) => {
      switch (ctx.lifecycle) {
        case 'init':
        case 'refresh': {
          const [rowData] = ctx.data;
          ctx.notify({ actionsConfig: rowActions(rowData) });
          break;
        }
        case 'change': {
          const { selectedId } = ctx.state;
          switch (selectedId) {
            case 'inline_notification_dismiss':
              return placeholderOnChange(ctx);
            case 'inline_notification_accept_io_new':
              return placeholderOnChange(ctx);
            case 'inline_notification_reject_io_new':
              return placeholderOnChange(ctx);
            case 'inline_notification_accept_pending_modification':
              return placeholderOnChange(ctx);
            case 'inline_notification_reject_pending_modificcation':
              return placeholderOnChange(ctx);
            case 'inline_notification_repair_failed_order':
              return placeholderOnChange(ctx);
            case 'inline_notification_retry_failed_order':
              return placeholderOnChange(ctx);
            case 'inline_notification_reject_failed_order':
              return placeholderOnChange(ctx);
            case 'inline_notification_repair_failed_order_modification':
              return placeholderOnChange(ctx);
            case 'inline_notification_retry_failed_order_modification':
              return placeholderOnChange(ctx);
            case 'inline_notification_reject_failed_order_modification':
              return placeholderOnChange(ctx);
            // TODO: Add other handlers here.
            default:
              throw new Error(`onChange not defined for ${String(selectedId)}`);
          }
        }
      }
    });

function rowActions(rowData: Notification): SplitButtonActionConfig[] {
  // TODO: Currently, the supported "notification name" types come from
  //       NotificationResourceTemplates and are not yet finalized.
  switch (rowData.name) {
    case NotificationResourceTemplates.IoExecution: {
      // Actions for "IO Execuion" TBD. For now make them dismissable.
      return [
        // Use the special char ✕ to display the close button.
        { id: 'inline_notification_dismiss', title: '✕' }
      ];
    }
    case NotificationResourceTemplates.IoNew: {
      return [
        { id: 'inline_notification_accept_io_new', title: 'Accept' },
        { id: 'inline_notification_reject_io_new', title: 'Reject' }
      ];
    }
    // The requirements specify a "IO Modify Request". Map this to PendingModification for now.
    case NotificationResourceTemplates.PendingModification: {
      return [
        { id: 'inline_notification_accept_pending_modification', title: 'Accept' },
        { id: 'inline_notification_reject_pending_modificcation', title: 'Reject' }
      ];
    }
    // The requirements specify a "IO Failed". Map this to FailedOrder for now.
    case NotificationResourceTemplates.FailedOrder: {
      return [
        { id: 'inline_notification_repair_failed_order', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order', title: 'Reject' }
      ];
    }
    // The requirements specify a "IO Failed - Modify". Map this to FailedOrderModification for now.
    case NotificationResourceTemplates.FailedOrderModification: {
      return [
        { id: 'inline_notification_repair_failed_order_modification', title: 'Repair' },
        { id: 'inline_notification_retry_failed_order_modification', title: 'Retry' },
        { id: 'inline_notification_reject_failed_order_modification', title: 'Reject' }
      ];
    }
    case NotificationResourceTemplates.PwDismissible:
    case NotificationResourceTemplates.PwPersist:
    case NotificationResourceTemplates.ApproachingDailyAccountLimit:
    case NotificationResourceTemplates.PendingCancellation:
    case NotificationResourceTemplates.TradingOrderUnsolicitedModifyRejected:
    case NotificationResourceTemplates.TradingOrderUnsolicitedModifyAccepted:
    case NotificationResourceTemplates.TradingOrderUnsolicitedCancel:
    // TODO: The requirements also specify the following, with their actions:
    //  - "Capital Threshold Warning" (dismiss)
    //  - "Failed Trade" (repair / retry / reject)
    //  - "Failed Trade - Modify" (repair / retry / reject)
    default:
      return [
        // Use the special char ✕ to display the close button.
        { id: 'inline_notification_dismiss', title: '✕' }
      ];
  }
}
