import type { Optional } from '@oms/shared/util-types';
import { cleanMaybe } from '@oms/shared/util';
import { type MontageUnboundTradingOrderFragment, OrderType } from '@oms/generated/frontend';
import type { MontageItem, MontageItemPriceInfo, MontageItemType, Level2QuotePage, MontageFilter, Level2IntegrationEvent } from '../montage.types';
import { calculateMontageItemQuantities, extractMontageItemPriceInfoFrom, getMontageItemTypeForSide, toMontageDate } from './montage.utils';
import type { MontageItemFromLevel2QuotePageOptions, MontageItemOptions } from './utils.types.internal';

export const convert = {
  /**
   * @param unboundTradingOrder - Pass an unbound trading order
   */
  fromUnboundTradingOrder: (unboundTradingOrder: MontageUnboundTradingOrderFragment) => ({
    /**
     * Converts unbound trading order to `MontageItem`
     * @param options.preferences - Pass the user montage preferences
     * @param options.roundingType - If rounding is needed, round down, up or nearest
     * @param options.fallbackVolume - Pass a fallback volume in case `quantity` is not specified in the trading order. Will default to zero if omitted.
     * @param options.fallbackLotSize - Pass a fallback lot size. If omitted, the constant default (100) will be used.
     * @param options.fallbackLimitPrice - Pass a fallback price in case `limitPrice` is not specified in the trading order. Will default to zero if omitted.
     * @param options.fallbackCounterPartyId - Pass a fallback counter-party ID in case destination venue is not specified in the trading order. Will default to empty string if omitted.
     * @param options.fallbackType - Pass a fallback type in case `side` is not specified in the trading order. Will default to `ask` if omitted.
     * @param options.tradingOrderIndex - Optionally, pass the trading order index. Defaults to undefined
     * @param options.isMarketMaker - Optionally, pass if order is market maker. Defaults to `undefined`
     * @param options.isMyTeam - Optionally, pass if order is team order. Defaults to `undefined`
     * @returns A `MontageItem` object
     */
    toMontageItem: (options?: MontageItemOptions): MontageItem => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { id, side, venueDestination, createdTimestamp } = unboundTradingOrder;
      const {
        targetingIndex: tradingOrderIndex,
        isMarketMaker,
        isMyTeam,
        fallbackLimitPrice = 0,
        fallbackType = 'ask',
        fallbackCounterPartyId = '',
        ...otherOptions
      } = options || {};
      const montageItem: MontageItem = {
        id,
        source: 'unboundTradingOrder',
        type: getMontageItemTypeForSide(side, fallbackType),
        time: toMontageDate(createdTimestamp),
        counterpartyId: cleanMaybe(venueDestination?.id, fallbackCounterPartyId),
        ...extractMontageItemPriceInfoFrom(unboundTradingOrder, fallbackLimitPrice),
        ...calculateMontageItemQuantities.fromUnboundTradingOrder(unboundTradingOrder, otherOptions)
      };
      if (typeof tradingOrderIndex === 'number') montageItem.targetingIndex = tradingOrderIndex;
      if (typeof isMarketMaker === 'boolean') montageItem.isMarketMaker = isMarketMaker;
      if (typeof isMyTeam === 'boolean') montageItem.isMyTeam = isMyTeam;
      return montageItem;
    }
  }),
  fromLevel2IntegrationEvent: (event: Level2IntegrationEvent) => ({
    toLevel2QuotePage(filter?: MontageFilter): Level2QuotePage[] {
      switch (filter?.type) {
        case 'ask':
          return event.asks;
        case 'bid':
          return event.bids;
        default:
          return [...event.asks, ...event.bids];
      }
    }
  }),
  /**
   * @param level2QuotePage - Pass an order page object
   */
  fromLevel2QuotePage: (level2QuotePage: Level2QuotePage) => ({
    /**
     * Converts an order page object to `MontageItem`
     * @param type - Specify montage order type, 'bid' or 'ask'
     * @param options.preferences - Pass the user montage preferences
     * @param options.instrument - Pass instrument fragment for lot size lookup
     * @param options.roundingType - If rounding is needed, round down, up or nearest
     * @param options.orderType - Pass an order type. Will default to `OrderType.Limit` if omitted.
     * @param options.fallbackLotSize - Pass a fallback lot size in case not on the instrument. If omitted, the constant default (100) will be used.
     * @param options.fallbackType - Pass a fallback type in case not specified by the filter. Will default to `ask` if omitted.
     * @param options.isMarketMaker - Optionally, pass if order is market maker. Defaults to `undefined`
     * @param options.isMyTeam - Optionally, pass if order is team order. Defaults to `undefined`
     * @returns A `MontageItem` object
     */
    toMontageItem: (type: Optional<MontageItemType>, options?: MontageItemFromLevel2QuotePageOptions): MontageItem => {
      const { id, price, time, marketMaker: counterpartyId } = level2QuotePage;
      const {
        isMarketMaker,
        isMyTeam,
        orderType = OrderType.Limit,
        fallbackType = 'ask',
        ...otherOptions
      } = options || {};
      const priceInfo: MontageItemPriceInfo = orderType === OrderType.Limit ? {
        orderType: OrderType.Limit,
        limitPrice: price
      } : { orderType: OrderType.Market };
      const montageItem: MontageItem = {
        id,
        source: 'level2Quote',
        type: type ?? fallbackType,
        time: toMontageDate(time),
        counterpartyId,
        ...priceInfo,
        ...calculateMontageItemQuantities.fromLevel2QuotePage(level2QuotePage, otherOptions)
      };
      if (typeof isMarketMaker === 'boolean') montageItem.isMarketMaker = isMarketMaker;
      if (typeof isMyTeam === 'boolean') montageItem.isMyTeam = isMyTeam;
      return montageItem;
    }
  })
};

export default convert;
