import { PROCESS_ID } from '@valstro/workspace';
import { TradeRepairRequestService } from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import { openConfirmation } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { type TradeRepairRequestFragment } from '@oms/generated/frontend';

function isVisible(data: TradeRepairRequestFragment[]) {
  return data.length >= 1;
}

export const retryCreateTradeOnChange = async (
  ctx: ActionContext<TradeRepairRequestFragment, ActionComponentConfig<TradeRepairRequestFragment>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    const ioRepairRequestService = appContainer.resolve(TradeRepairRequestService);
    const selectedRowsId = data.map((row) => row.id!);
    const count = selectedRowsId.length;

    try {
      const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
        title: `Retry ${count} trade${count > 1 ? 's' : ''}`,
        componentProps: {
          autoClose: true,
          message: `Are you sure you want to retry ${count} trade${count > 1 ? 's' : ''}?`,
          confirmButtonText: t('app.common.yes'),
          confirmButtonProps: {
            variant: 'primary',
            palette: 'standard'
          },
          cancelButtonText: t('app.common.no')
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          const result = await ioRepairRequestService.retryCreateTradeRequests(selectedRowsId);
          ctx.notify({ isLoading: false });

          if (result.isFailure()) {
            const msgs = result?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
            throw new Error(msgs);
          }
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const retryCreateTradeAction: ActionDefFactory<TradeRepairRequestFragment> = (builder) =>
  builder
    .name('retry_investor_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('retry_investor_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Retry'
        })
    )
    .customMenu((m) => m.name('Retry').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TradeRepairRequestFragment>>(retryCreateTradeOnChange);
