import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ComboBoxItem, ComboBoxItemUnion } from '../../../combo-box/combo-box.types';
import type { AutocompleteProps } from '../../../popovers/autocomplete/popover.autocomplete';
import { type Subscription } from 'rxjs';
import { type IAdvancedMultiSelectField } from './advanced-multi-select.types';
import { type IAdvancedSelectField } from './advanced-select.types';
import {
  type AdvancedSelectQueryFn,
  type AdvancedSelectQueryPayload
} from './query-mapper/query-mapper.types';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { type UseFieldApiProps, useFormApi } from '@data-driven-forms/react-form-renderer';
import { useWorkspaceContainer } from '../../../workspace/workspace.services';
import { isPromiseLike } from '@oms/shared/util';
import { useDeepCompareEffect } from 'react-use';
import { useWorkspaceFormMappers } from '../../common/form.workspace.hook';
import { useDeepMemo } from '@oms/shared-frontend/ui-design-system';
import { isEqual } from 'lodash';
import { extractAndFlattenItems } from '../../../combo-box/util/flatten-combo-box-items';

const defaultProps: AutocompleteProps = {
  options: [],
  value: [],
  name: 'autocomplete',
  inputProps: {
    style: {
      maxWidth: 400
    }
  },
  isMultiSelect: false,
  filterStrategy: 'default',
  onItemClick: undefined
};

export type UseAdvancedSelectQueryProps = UseFieldApiProps<any, HTMLElement> &
  (
    | {
        id: string;
        label?: string | null;
        value?: unknown;
      }
    | IAdvancedSelectField
    | IAdvancedMultiSelectField
    | {
        id: string;
        label?: string | null;
        value?: unknown;
      }[]
  );

export const useAdvancedSelectQuery = (field: UseAdvancedSelectQueryProps, isMultiSelect = false) => {
  const form = useFormApi();
  const {
    label,
    input: { name, onBlur, onChange, onFocus, value },
    isLoading: _isLoading,
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    inputProps = {},
    options: _options = [],
    style = {},
    sx = {},
    wrapperSx = {},
    query: _query,
    autoSelectItemOnTab,
    isTypeahead,
    autoSizeWidth,
    autoSizeWidthToTrigger = true,
    width,
    minWidth,
    minHeight,
    allowAnyValue = false,
    formatValue,
    fallbackValue,
    autoFocus,
    filterStrategy,
    optionsOnFocus: _optionsOnFocus,
    queryProps = {},
    onItemClick,
    hideFormControls
  } = field;
  const valueArr = useMemo(() => (Array.isArray(value) ? value : value ? [value] : []), [value]);
  const [internalValue, setInternalValue] = useState<ComboBoxItem[]>([]);

  const internalValueIds = useMemo(() => internalValue.map((v) => v.id), [internalValue]);
  const hasInitializedRef = useRef(false);
  const mappers = useWorkspaceFormMappers();
  const container = useWorkspaceContainer();
  const subscription = useRef<Subscription | undefined>();
  const queryReturn: ReturnType<AdvancedSelectQueryFn> | undefined = useDeepMemo(() => {
    if (!_query) {
      return undefined;
    }
    if (typeof _query === 'function') {
      return _query(container);
    }

    const queryFromMapper = mappers.advancedSelectQueryMapper[_query];
    if (!queryFromMapper) {
      throw new Error(`Advanced select query with key ${_query} not found in workspace meta.`);
    }
    return queryFromMapper(container);
  }, [_query, container, mappers]);

  const [options, setOptions] = useState<ComboBoxItemUnion<any>[]>([]);
  const [hasFetchedOptions, setHasFetchedOptions] = useState(queryReturn === undefined);
  const mergedOptions = useMemo(() => (_options.length > 0 ? _options : options), [_options, options]);
  const flattenedOptions = useMemo(() => extractAndFlattenItems(mergedOptions), [mergedOptions]);
  const [isLoading, setIsLoading] = useState(_isLoading !== undefined ? _isLoading : false);
  const [isInputLoading, setIsInputLoading] = useState(valueArr.length > 0);

  // Combine field/input props
  const fieldProps = useDeepMemo(
    () => ({
      name,
      onBlur,
      onFocus,
      hidden: isVisible === false,
      style: {
        ...style,
        ...(inputProps.style || {}),
        height: isPrimaryField ? 'var(--controls-heights-md)' : undefined
      },
      sx,
      isReadOnly,
      isDisabled: !!forceIsDisabled || !!_isDisabled
    }),
    [
      name,
      onBlur,
      onFocus,
      isVisible,
      style,
      inputProps.style,
      sx,
      isReadOnly,
      forceIsDisabled,
      _isDisabled,
      isPrimaryField
    ]
  );

  // Combine field wrapper props
  const fieldWrapperProps = useDeepMemo(
    () => ({
      label,
      isReadOnly,
      isRequired,
      // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
      isDisabled: !!forceIsDisabled || !!_isDisabled,
      isVisible,
      isInvalid,
      helperText,
      isFeatureField,
      isPrimaryField,
      requiredFieldIndicatorStyle,
      hideFormControls,
      sx: wrapperSx
    }),
    [
      label,
      isReadOnly,
      isRequired,
      forceIsDisabled,
      _isDisabled,
      isVisible,
      isInvalid,
      helperText,
      isFeatureField,
      isPrimaryField,
      requiredFieldIndicatorStyle,
      wrapperSx
    ]
  );

  const handleOnChange = useCallback(
    (values: ComboBoxItem<any>[]) => {
      if (isMultiSelect) {
        const selectedItems = values?.length ? values : [];
        const selectedValues = selectedItems.length
          ? selectedItems.map((v) =>
              v.value && !isEqual(v.id, v.value) ? { id: v.id, value: v.value } : { id: v.id }
            )
          : undefined;
        setInternalValue(selectedItems);
        onChange(selectedValues);
      } else {
        const selectedItem = values?.[0];
        const selectedValue = selectedItem
          ? selectedItem.value && !isEqual(selectedItem.id, selectedItem.value)
            ? { id: selectedItem.id, value: selectedItem.value }
            : { id: selectedItem.id }
          : undefined;
        setInternalValue(selectedItem ? [selectedItem] : []);
        onChange(selectedValue);
      }
    },
    [onChange, isMultiSelect]
  );

  // Unsubscribe on unmount or queryReturn change
  useEffect(() => {
    return () => {
      if (queryReturn) {
        queryReturn.unsubscribe?.();
      }
    };
  }, [queryReturn]);

  // Unsubscribe on unmount
  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current?.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (options?.length && !queryReturn) {
      subscription.current = undefined;
      setOptions([]);
    }
  }, [options, queryReturn]);

  // Preload query on mount
  useDeepCompareEffect(() => {
    if (queryReturn && queryReturn.type === 'watchAll' && subscription.current === undefined) {
      subscription.current = queryReturn
        .query({
          field,
          form,
          queryProps
        })
        .subscribe((response) => {
          setIsLoading(Boolean(response.isFetching));

          if (response.isFetching === false && Array.isArray(response.results) && !response.error) {
            const options = response.results as ComboBoxItemUnion<any>[];
            setOptions(options);
            setHasFetchedOptions(true);
          }

          if (response.error) {
            console.error(response.error);
            setIsInputLoading(false);
          }
        });
    }
  }, [queryReturn, queryProps, valueArr.length]);

  const typeaheadCallback = useCallback((response: AdvancedSelectQueryPayload<any>) => {
    setIsLoading(Boolean(response.isFetching));
    setOptions(response.results as ComboBoxItemUnion<any>[]);
  }, []);

  const debouncedTypeaheadCallback = useDebouncedCallback(
    (response: AdvancedSelectQueryPayload<any>) => {
      typeaheadCallback(response);
    },
    [],
    queryReturn && queryReturn.type === 'typeahead' && queryReturn.debounceMs ? queryReturn.debounceMs : 100
  );

  // Unsubscribe on unmount or queryReturn change
  useEffect(() => {
    return () => {
      // Run unsubscribe on unmount/queryReturn change
      queryReturn?.unsubscribe?.();

      if (queryReturn && queryReturn.type === 'typeahead' && queryReturn.debounceMs) {
        // Cancel debounced callback on unmount/queryReturn change
        debouncedTypeaheadCallback.cancel();
      }
    };
  }, [debouncedTypeaheadCallback, queryReturn]);

  const handleOnInputValueChange = useCallback(
    (val: string) => {
      if (queryReturn && queryReturn.type === 'typeahead') {
        const useInternalDebounce = queryReturn.debounceMs !== undefined;
        if (useInternalDebounce) {
          debouncedTypeaheadCallback.cancel();
          queryReturn?.unsubscribe?.();
        }
        const queryRes = queryReturn.query({
          field,
          form,
          inputValue: val,
          callback: useInternalDebounce ? debouncedTypeaheadCallback : typeaheadCallback,
          queryProps
        });

        if (isPromiseLike(queryRes)) {
          queryRes.catch(console.error);
        }
      }
    },
    [queryReturn, field, form, debouncedTypeaheadCallback, typeaheadCallback, queryProps]
  );

  const optionsOnFocus = useDeepMemo(() => {
    return _optionsOnFocus !== undefined
      ? _optionsOnFocus
      : queryReturn?.type === 'watchAll' || _optionsOnFocus || _options.length > 0;
  }, [_options.length, _optionsOnFocus, queryReturn?.type]);

  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onFocus) {
        onFocus(e);
      }

      if (queryReturn && queryReturn.type === 'typeahead' && optionsOnFocus) {
        handleOnInputValueChange(e.target.value);
      }
    },
    [field, form, handleOnInputValueChange, onFocus, optionsOnFocus, queryProps, queryReturn]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur]
  );

  // Listen to selected value changes (ids) and update internal value (ComboBoxItem[]) to show correct label in the input
  useEffect(() => {
    const isDiff =
      internalValue.length !== valueArr.length || internalValue.some((v, i) => v.id !== valueArr[i].id);

    if (!isDiff) {
      return;
    }

    // If internal value has a value & incoming value is empty, clear the internal value, regardless of hasFetchedOptions & queryReturn
    if (valueArr.length === 0 && internalValue.length > 0) {
      setInternalValue([]);
      setIsInputLoading(false);
      return;
    }

    if (queryReturn?.type !== 'typeahead' && hasFetchedOptions) {
      const selectedValues = valueArr.map((val) => {
        const isAlreadyInValue = flattenedOptions.find((v) => v.id === val.id);
        if (isAlreadyInValue) {
          return isAlreadyInValue;
        }

        const blankItem: ComboBoxItem = {
          type: 'item',
          id: val.id,
          label: val.label || val.id,
          value: val.id
        };

        return blankItem;
      });
      hasInitializedRef.current = true;
      setInternalValue(selectedValues);
      setIsInputLoading(false);
      return;
    }

    if (queryReturn?.type === 'typeahead' && isDiff) {
      async function fetchValues() {
        if (queryReturn?.type !== 'typeahead') {
          return;
        }

        const selectedValues = await Promise.all(
          valueArr.map(async (val) => {
            // Use already selected result if it exists
            const isAlreadyInValue = internalValue.find((v) => v.id === val.id);
            if (isAlreadyInValue) {
              return isAlreadyInValue;
            }

            // Otherwise fetch the value
            const result = await queryReturn?.findOne({
              id: val.id,
              field,
              form,
              queryProps
            });

            if (result) {
              return result;
            }

            const blankItem: ComboBoxItem = {
              type: 'item',
              id: val.id,
              label: val.label || val.id,
              value: val.id
            };

            return blankItem;
          })
        );

        hasInitializedRef.current = true;
        setInternalValue(selectedValues as ComboBoxItem[]);
        setIsInputLoading(false);
      }

      fetchValues().catch(console.error);
    }
  }, [valueArr, internalValueIds, hasFetchedOptions]);

  const autoCompleteProps: AutocompleteProps = useMemo(
    () => ({
      ...defaultProps,
      name,
      options: mergedOptions,
      isMultiSelect,
      isLoading: _isLoading !== undefined ? _isLoading : isLoading,
      isInputLoading,
      onChange: handleOnChange,
      onInputValueChange: handleOnInputValueChange,
      filterStrategy:
        filterStrategy !== undefined
          ? filterStrategy
          : queryReturn?.type === 'typeahead'
          ? 'none'
          : 'default',
      value: internalValue,
      inputProps: {
        ...inputProps,
        ...fieldProps,
        autoFocus,
        onBlur: handleOnBlur,
        onFocus: handleOnFocus,
        variant: isPrimaryField || isFeatureField ? 'primary' : 'default'
      },
      isTypeahead: isTypeahead !== undefined ? isTypeahead : queryReturn?.type === 'typeahead',
      optionsOnFocus: optionsOnFocus,
      autoSelectItemOnTab:
        autoSelectItemOnTab !== undefined ? autoSelectItemOnTab : isMultiSelect ? false : true,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      width,
      minWidth,
      minHeight,
      allowAnyValue,
      formatValue,
      fallbackValue,
      onItemClick
    }),
    [
      name,
      mergedOptions,
      isMultiSelect,
      isLoading,
      _isLoading,
      isInputLoading,
      handleOnChange,
      handleOnInputValueChange,
      queryReturn?.type,
      internalValue,
      inputProps,
      fieldProps,
      autoFocus,
      handleOnBlur,
      handleOnFocus,
      isPrimaryField,
      isFeatureField,
      isTypeahead,
      optionsOnFocus,
      autoSelectItemOnTab,
      autoSizeWidth,
      autoSizeWidthToTrigger,
      width,
      minWidth,
      minHeight,
      allowAnyValue,
      formatValue,
      fallbackValue,
      filterStrategy,
      onItemClick
    ]
  );

  return useMemo(() => ({ fieldWrapperProps, autoCompleteProps }), [autoCompleteProps, fieldWrapperProps]);
};
