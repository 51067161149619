import { type ActionDefFactory } from '@oms/frontend-vgrid';
import { PlusIcon } from '@radix-ui/react-icons';
import type { TradingOrderRow } from '../trading-order-monitor.contracts';
import { openCreateTradingOrder } from '@app/widgets/trading/route-order/route-order.form-open';

export const createOpenEntryTradingOrderAction: ActionDefFactory<TradingOrderRow> = (builder) =>
  builder
    .name('open_trading_order_entry_trading_order')
    .toolbar((t) =>
      t
        .id('open_trading_order_entry_trading_order_button')
        .component('action-button')
        .location('HorizontalToolbarLeft')
        .props({
          variant: 'link',
          leftIcon: <PlusIcon />,
          content: 'Trading Order Entry'
        })
    )
    .onChange((ctx) => {
      if (ctx.lifecycle !== 'change') return;
      openCreateTradingOrder(ctx.workspace);
    });
