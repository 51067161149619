import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  type AnyFormValues,
  type FieldProps,
  FieldWrapper,
  useEnhancedFormApi,
  useFieldApi
} from '@oms/frontend-foundation';
import { type ICommonField } from '@oms/frontend-foundation';
import { type ValidatorType } from '@data-driven-forms/react-form-renderer';
import { type FORM_COMPONENT_TYPE } from '../../../../../forms/form-builder/common/form.contracts';
import { MatchedInvestorOrdersCheckboxGroup } from '../../../../../containers/matched-investor-orders/matched-investor-orders-checkbox-group';
import { type TradingOrderValues } from '@app/data-access/services/trading/investor-orders/matchable-investor-orders.subscriptions.service';
import { get } from 'lodash';
import objectHash from 'object-hash';
import { MatchedInvestorOrderSummary } from '../../../../../containers/matched-investor-orders/matched-investor-order-summary';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { orderSideTypeToOrderSideMapping } from '@app/common/mappers/order-enums';
import { type VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import type { RouteOrderFormValues } from '@app/widgets/trading/route-order/route-order.form-contract';

export type IMatchedInvestorOrdersField<TValidate = ValidatorType> = ICommonField<
  typeof FORM_COMPONENT_TYPE.MATCHED_INVESTOR_ORDERS_FIELD,
  string[],
  TValidate
>;

function getTradingOrderValuesFromFormValues(formValues: AnyFormValues): TradingOrderValues {
  return {
    instrumentId: get(formValues, 'instrument')?.id as TradingOrderValues['instrumentId'],
    limitPrice: get(formValues, 'limitPrice') as TradingOrderValues['limitPrice'],
    side: orderSideTypeToOrderSideMapping[
      get(formValues, 'sideType') as NonNullable<TradingOrderValues['side']>
    ],
    accountId: get(formValues, 'firmAccount')?.id as TradingOrderValues['accountId']
  };
}

export const MatchedInvestorOrdersField: React.FC<FieldProps<IMatchedInvestorOrdersField>> = (props) => {
  const {
    meta,
    input,
    autoFocus = false,
    isDisabled: _isDisabled,
    forceIsDisabled,
    hidden,
    label,
    isInvalid,
    isVisible = true,
    helperText,
    isRequired,
    isReadOnly,
    hideFormControls
  } = useFieldApi<IMatchedInvestorOrdersField>(props);

  const [tradingOrderValues, setTradingOrderValues] = useState<TradingOrderValues | null>(null);

  const prevHash = useRef<string>('');

  const form = useEnhancedFormApi<RouteOrderFormValues>();
  const isModifyMode = form.getState().values.hiddenMode?.type === 'modify';

  useEffect(() => {
    const unsubscribe = form.subscribe(
      (f) => {
        const values = f.values;
        // use hash to compare form states between changes
        const hash = objectHash(getTradingOrderValuesFromFormValues(values));

        if (hash !== prevHash.current) {
          prevHash.current = hash;
          const tradingOrderValuesFromForm = getTradingOrderValuesFromFormValues(values);
          setTradingOrderValues(tradingOrderValuesFromForm);
        }
      },
      { values: true }
    );
    return () => {
      unsubscribe();
    };
  }, [form]);

  // Sometimes validators override the disabled props. If forceIsDisabled is true, then we should always disable the field.
  const isDisabled = !!forceIsDisabled || !!_isDisabled;

  const [selectedOrders, setSelectedOrders] = useState<VisibleInvestorOrderInfoWithAllocationsFragment[]>([]);

  const { onChange } = input;

  const handleSelectOrders = useCallback(
    (checkedOrders: VisibleInvestorOrderInfoWithAllocationsFragment[]) => {
      setSelectedOrders(checkedOrders);
      onChange(checkedOrders.map((o) => o.id));
    },
    [setSelectedOrders, onChange]
  );

  return (
    <FieldWrapper
      meta={meta}
      hideFormControls={hideFormControls}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      hidden={hidden}
      autoFocus={autoFocus}
      helperText={helperText}
      isVisible={isVisible}
    >
      <Flex align="center" sx={{ position: 'relative' }} style={{ minHeight: '4.5rem' }}>
        <Flex>
          {isVisible && tradingOrderValues && !isModifyMode && (
            <MatchedInvestorOrdersCheckboxGroup
              instrumentId={tradingOrderValues.instrumentId}
              side={tradingOrderValues.side}
              limitPrice={tradingOrderValues.limitPrice}
              placement="trading-order-entry"
              handleSelectOrders={handleSelectOrders}
            />
          )}
        </Flex>
        <Flex
          style={{ marginLeft: 'auto', width: '100%', maxWidth: '30em', minWidth: '25em', height: '100%' }}
          sx={{ position: 'relative' }}
        >
          {tradingOrderValues && (
            <MatchedInvestorOrderSummary
              showTradeAlong
              orders={selectedOrders}
              instrumentId={tradingOrderValues.instrumentId}
              side={tradingOrderValues.side}
              accountId={tradingOrderValues.accountId}
              isModifyMode={isModifyMode}
            />
          )}
        </Flex>
      </Flex>
    </FieldWrapper>
  );
};
