import { FORM_EVENT_TYPE, FormBuilder, GQLResult } from '@oms/frontend-foundation';
import type {
  ActionButtonLayoutContractType,
  ActionButtonLayoutValues
} from './action-button-layout.form-contract';
import { actionButtonLayoutContract } from './action-button-layout.form-contract';
import {
  type ActionButtonLayoutFormInput,
  type ActionButtonLayoutFormOutput
} from './action-button-layout.contracts';
import { OfflineActionService } from '@app/actions/services/offline.action.service';
import { ACTION_COLOR_LABELS, ACTION_DEFINED_COLORS, type LayoutAction } from '../types';
import omit from 'lodash/omit';
import { ACTION_COMMANDS_TO_LABELS, ACTION_LABELS_TO_COMMANDS } from '../utils/button.action.schema';
import { GeneralError } from '@oms/shared/oms-common';
import { type ActionCommands } from '@app/actions/commands/command.registry.types';
import { Actor } from '@valstro/workspace';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

export const actionButtonLayoutFormBuilder = FormBuilder.create<
  ActionButtonLayoutFormInput,
  ActionButtonLayoutFormOutput['layout']
>('action-layout-form')
  .contract<ActionButtonLayoutContractType>(actionButtonLayoutContract)
  .type('action-layout-form')
  .sanitizer((s) =>
    s
      .input(async function sanitize(incomingValues, ctx) {
        const { container } = ctx;
        const offlineActionsSvc = container.resolve(OfflineActionService);
        const actions = await offlineActionsSvc.actionsBy({
          ...omit(incomingValues, 'allowedCommands')
        });
        const { allowedCommands = [] } = incomingValues;

        const values: ActionButtonLayoutValues = {
          layout: {
            requiredFields: incomingValues as ActionButtonLayoutFormInput,
            actions: actions
              .filter((a) => allowedCommands.includes(a.commandId) || !allowedCommands?.length)
              .map((a) => ({
                ...a,
                commandId: ACTION_COMMANDS_TO_LABELS[a.commandId as ActionCommands],
                color: ACTION_DEFINED_COLORS.get(a.color.backgroundColor) || 'Blue'
              })) as LayoutAction[]
          }
        };

        return values;
      })
      .output(function sanitize(formValues) {
        const output = {
          actions: (formValues.layout?.actions || []).map((a) => ({
            ...omit(
              a,
              'id',
              'allowedCommands',
              'allowsColor',
              'allowsConfirmation',
              'allowsLabel',
              'allowsSize'
            ),
            id: OfflineActionService.idOf({
              ...a,
              color: ACTION_COLOR_LABELS[a.color],
              commandId: ACTION_LABELS_TO_COMMANDS[a.commandId]
            }),
            commandId: ACTION_LABELS_TO_COMMANDS[a.commandId],
            color: ACTION_COLOR_LABELS[a.color]
          })),
          requiredFields: omit(formValues.layout?.requiredFields, 'allowedCommands')
        } as ActionButtonLayoutFormOutput['layout'];

        return output;
      })
  )
  .change(async (event, ctx) => {
    const offlineActionsSvc = ctx.container.resolve(OfflineActionService);
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.SUBMIT: {
        const { output } = event.payload;
        const { actions } = output;

        const changes = await offlineActionsSvc.merge(output.requiredFields, ...actions);

        const { upserts, deletes } = changes;

        const results = await Promise.allSettled([
          offlineActionsSvc.delete(...deletes),
          offlineActionsSvc.upsert(...upserts)
        ]);

        const generalErrors: GeneralError[] = results.reduce((errors, promise) => {
          if (promise.status === 'rejected') {
            errors.push(new GeneralError(String(promise.reason)));
          }
          return errors;
        }, [] as GeneralError[]);

        if (generalErrors.length) {
          return GQLResult.failure(generalErrors);
        }

        Actor.get<AppWindowActorSchema>(event.meta.windowId)
          .then((actor) => {
            actor.operations.close().catch(console.error);
          })
          .catch(console.error);

        break;
      }
      case FORM_EVENT_TYPE.VALUES_CHANGED:
        break;
    }
  });

export default actionButtonLayoutFormBuilder;
