import { type ActionDefFactory } from '@oms/frontend-vgrid';
import {
  InvestorOrderStatus,
  type VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import { PlusIcon } from '@radix-ui/react-icons';
import { type InvestorOrderEntryType } from '../../investor-order-entry/investor-order-entry.form-common';
import { openInvestorOrderModify } from '@app/common/types/orders/investor-order/open.modify.investor-order';

const isModifyOrderEnabled = (
  mode: InvestorOrderEntryType,
  rowData: VisibleInvestorOrderInfoWithAllocationsFragment[]
): boolean =>
  mode === 'update' &&
  rowData !== undefined &&
  rowData.length === 1 &&
  !!rowData[0] &&
  rowData[0].status === InvestorOrderStatus.Active &&
  rowData[0].__typename === 'VisibleInvestorOrder';

export const openIoEntryInvestorOrderAction: (
  mode: InvestorOrderEntryType
) => ActionDefFactory<VisibleInvestorOrderInfoWithAllocationsFragment> = (mode) => (builder) =>
  builder
    .name(`io_${mode}`)
    .toolbar((t) =>
      t
        .component('action-button')
        .id(`order_${mode}_button`)
        .location(mode === 'create' ? 'HorizontalToolbarLeft' : 'HorizontalToolbarRight')
        .props({
          isDisabled: mode === 'update',
          variant: mode === 'create' ? 'link' : 'primary',
          leftIcon: mode === 'create' ? <PlusIcon /> : undefined,
          content: mode === 'create' ? 'Order Entry' : 'Modify'
        })
    )
    .customMenu((m) =>
      m
        .name('Modify Order')
        .visible(({ rowData }) => isModifyOrderEnabled(mode, rowData))
        .primary()
    )
    .onChange(async (ctx) => {
      const { data, lifecycle, workspace } = ctx;

      const selectedRow = data?.[0];
      const isOnlyOneRowSelected = data?.length === 1;

      const isModifiable =
        mode === 'create' || (isOnlyOneRowSelected && isModifyOrderEnabled(mode, [selectedRow]));

      ctx.notify({ isDisabled: !isModifiable });

      if (lifecycle === 'change') {
        //TODO: useLeaderOrTabId - use this in grid lib. and pass here.
        //TODO: So that if users have two tabs open the widget will open in the tab they are on.
        await openInvestorOrderModify(workspace.getLeaderProcessId(), mode, ctx?.data?.[0]?.id);
      }
    });
