import { type StrategyPanel, type StrategyQuery } from '@valstro/fixatdl-core';
import { fixatdlFormComponentTypes } from '../../fixatdl-component.mappers';
import { transformControl } from './control.transformer';
import { type AtdlFieldPartial } from './control.transformer.types';
import { type FieldProps } from '@oms/frontend-foundation';
import { type FIXatdlField } from '@app/forms/fixatdl/fixatdl-form.types';

export const transformPanels = (
  panels: StrategyPanel[],
  seenPanels: Map<string, number>,
  query: StrategyQuery,
  formValues: Record<string, any>
): AtdlFieldPartial[] => {
  if (!panels.length) return [];

  return panels.map((p) => {
    const seenCount = seenPanels.get(p.title || '');
    seenPanels.set(p.title || '', (seenCount || 0) + 1);
    const formId = `${p.title || ''}_${seenPanels.get(p.title || '') || ''}`;

    return {
      component: fixatdlFormComponentTypes.STRATEGY_PANEL,
      fields: (p.controls || [])
        .map((c) => transformControl(c, query, formValues))
        .concat(transformPanels(p.panels || [], seenPanels, query, formValues))
        .filter((f) => !!f),
      orientation: p.controls && p.controls?.length < 3 ? 'HORIZONTAL' : p.orientation,
      title: p.title,
      name: formId,
      collapsible: p.collapsible,
      collapsed: p.collapsed,
      sx: {
        bg: !p.panels || p.panels.length === 0 ? 'layout.level2' : 'layout.level1',
        padding: p.panels ? (p.title ? 2 : 0) : 4,
        marginBottom: p.panels && !p.title ? 0 : 2
      }
    } as FieldProps<FIXatdlField>;
  });
};
